export default function IconSale() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#F1F6E6"/>
      <mask id="mask0_3823_14805" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
      <circle cx="28" cy="28" r="28" fill="#F1F6E6"/>
      </mask>
      <g mask="url(#mask0_3823_14805)">
      <path d="M9.36651 19.6887C9.48261 18.2332 10.6976 17.1113 12.1576 17.1113H43.8413C45.3013 17.1113 46.5163 18.2332 46.6324 19.6887L49.5361 56.0887C49.6661 57.7177 48.3792 59.1113 46.745 59.1113H9.25394C7.61976 59.1113 6.33285 57.7177 6.4628 56.0887L9.36651 19.6887Z" fill="url(#paint0_linear_3823_14805)"/>
      </g>
      <path d="M37.3327 20.222C37.3327 15.0673 33.154 10.8887 27.9993 10.8887C22.8447 10.8887 18.666 15.0673 18.666 20.222" stroke="url(#paint1_linear_3823_14805)" stroke-width="2.8" stroke-linecap="round"/>
      <path d="M27.4668 29.6391C27.6344 29.1232 28.3643 29.1232 28.5319 29.6391L29.9691 34.0622C30.0441 34.293 30.2591 34.4492 30.5017 34.4492H35.1524C35.6949 34.4492 35.9204 35.1434 35.4815 35.4622L31.719 38.1958C31.5228 38.3384 31.4406 38.5912 31.5156 38.8219L32.9528 43.245C33.1204 43.7609 32.5299 44.19 32.091 43.8711L28.3285 41.1375C28.1322 40.9949 27.8665 40.9949 27.6702 41.1375L23.9077 43.8711C23.4688 44.19 22.8783 43.7609 23.0459 43.245L24.4831 38.8219C24.5581 38.5912 24.4759 38.3384 24.2797 38.1958L20.5172 35.4622C20.0783 35.1434 20.3038 34.4492 20.8463 34.4492H25.497C25.7396 34.4492 25.9546 34.293 26.0296 34.0622L27.4668 29.6391Z" fill="#F1F6E6"/>
      <defs>
      <linearGradient id="paint0_linear_3823_14805" x1="27.9995" y1="17.1113" x2="27.9995" y2="59.1113" gradientUnits="userSpaceOnUse">
      <stop stop-color="#97BD50"/>
      <stop offset="1" stop-color="#C1DE8A"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3823_14805" x1="27.9993" y1="10.8887" x2="27.9993" y2="20.222" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9BC055"/>
      <stop offset="1" stop-color="#BBDA82"/>
      </linearGradient>
      </defs>
    </svg>
  )
}