import { HttpApiCatalogType } from '../RightElement';
import { CE_TYPE_KEY } from './apiPath';

type TScListItemData = {
  label: CE_TYPE_KEY;
  desc: string;
}

type TScListData = {
  title: string;
  list: TScListItemData[];
}

export const ceData: TScListData[] = [
  {
    title: 'OverView',
    list: [
      {
        label: 'Quick Start',
        desc: 'Comming Soon.',
      },
    ],
  },
  {
    title: 'Themes',
    list: [
      {
        label: 'Themes',
        desc: 'Themes.',
      },
    ],
  },
  {
    title: 'Posts',
    list: [
      {
        label: 'Posts',
        desc: 'Posts.',
      },
    ],
  },
  {
    title: 'Pages',
    list: [
      {
        label: 'Pages',
        desc: 'Pages.',
      },
    ],
  },
  {
    title: 'Collections',
    list: [
      {
        label: 'Collections',
        desc: 'Collections.',
      },
    ],
  },
  {
    title: 'Content type',
    list: [
      {
        label: 'Content type',
        desc: 'Content type.',
      },
    ],
  },
  {
    title: 'Webhook',
    list: [
      {
        label: 'Webhook',
        desc: 'Webhook.',
      },
    ],
  },
]