import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  // Oms
  'Inventory': {
    v10: '/mdpreview/Composable_Fulfillment/Inventory/Inventory.md',
    v11: '/mdpreview/Composable_Fulfillment/Inventory/Inventory.md',
    v12: '/mdpreview/Composable_Fulfillment/Inventory/Inventory.md',
    v13: '/v13/Composable_Fulfillment/Inventory/Inventory.md',
  },
  'Sourcing Rule': {
    v10: '/mdpreview/Composable_Fulfillment/Tactics/sourcing_rule.md',
    v11: '/mdpreview/Composable_Fulfillment/Tactics/sourcing_rule.md',
    v12: '/mdpreview/Composable_Fulfillment/Tactics/sourcing_rule.md',
    v13: '/v13/Composable_Fulfillment/Tactics/sourcing_rule.md',
  },
  'Inventory Network': {
    v10: '/mdpreview/Composable_Fulfillment/Tactics/warehouse.md',
    v11: '/mdpreview/Composable_Fulfillment/Tactics/warehouse.md',
    v12: '/mdpreview/Composable_Fulfillment/Tactics/warehouse.md',
    v13: '/v13/Composable_Fulfillment/Tactics/warehouse.md',
  },
  'Order': {
    v10: '/mdpreview/Composable_Fulfillment/Orders/Order.md',
    v11: '/mdpreview/Composable_Fulfillment/Orders/Order.md',
    v12: '/mdpreview/Composable_Fulfillment/Orders/Order.md',
    v13: '/v13/Composable_Fulfillment/Orders/Order.md',
  },
  'Inbound': {
    v10: '/mdpreview/Composable_Fulfillment/Outbound&Inbound/Inbound.md',
    v11: '/mdpreview/Composable_Fulfillment/Outbound&Inbound/Inbound.md',
    v12: '/mdpreview/Composable_Fulfillment/Outbound&Inbound/Inbound.md',
    v13: '/v13/Composable_Fulfillment/Outbound&Inbound/Inbound.md',
  },
  'Outbound': {
    v10: '/mdpreview/Composable_Fulfillment/Outbound&Inbound/Outbound.md',
    v11: '/mdpreview/Composable_Fulfillment/Outbound&Inbound/Outbound.md',
    v12: '/mdpreview/Composable_Fulfillment/Outbound&Inbound/Outbound.md',
    v13: '/v13/Composable_Fulfillment/Outbound&Inbound/Outbound.md',
  },
})

export type FAAS_Fulfillment_TYPE_KEY = keyof typeof apiPath

export default apiPath