export const Footer = () => {
  return (
    <div className="h-am80 flex  justify-center  w-full  mt-am_7 leading-am80">
      <span className="mr-6">©API DTC Open Platform 2023 </span>
      <span className="mr-6">Privacy</span>
      <span className="mr-6">Terms</span>
      <span className="mr-6">Help</span>
      <span className="mr-6"> Send Enquiry</span>
    </div>
  )
}
