import {defineApiPath} from "../RightElement/apiPath"

const apiPath = defineApiPath({
    default: {
        v10: '',
        v11: '',
    },
    // Content
    'Content': {
        v10: '/mdpreview/Composable_CMS/content/Content.md',
        v11: '/mdpreview/Composable_CMS/content/Content.md',
        v12: '/mdpreview/Composable_CMS/content/Content.md',
        v13: '/v13/Composable_CMS/content/Content.md',
    },
    'Content Type': {
        v10: '/mdpreview/Composable_CMS/content/Content_type.md',
        v11: '/mdpreview/Composable_CMS/content/Content_type.md',
        v12: '/mdpreview/Composable_CMS/content/Content_type.md',
        v13: '/v13/Composable_CMS/content/Content_type.md',
    },
    'Meta Data': {
        v10: '/mdpreview/Composable_CMS/content/Meta_Data.md',
        v11: '/mdpreview/Composable_CMS/content/Meta_Data.md',
        v12: '/mdpreview/Composable_CMS/content/Meta_Data.md',
        v13: '/v13/Composable_CMS/content/Meta_Data.md',
    },
    'Blog': {
        v10: '/mdpreview/Composable_CMS/content/Blog.md',
        v11: '/mdpreview/Composable_CMS/content/Blog.md',
        v12: '/mdpreview/Composable_CMS/content/Blog.md',
        v13: '/v13/Composable_CMS/content/Blog.md',
    },
    'Assets': {
        v10: '/mdpreview/Composable_CMS/content/Assets.md',
        v11: '/mdpreview/Composable_CMS/content/Assets.md',
        v12: '/mdpreview/Composable_CMS/content/Assets.md',
        v13: '/v13/Composable_CMS/content/Assets.md',
    },
    'Catalog': {
        v10: '/mdpreview/Composable_CMS/content/Catalog.md',
        v11: '/mdpreview/Composable_CMS/content/Catalog.md',
        v12: '/mdpreview/Composable_CMS/content/Catalog.md',
        v13: '/v13/Composable_CMS/content/Catalog.md',
    },
    'Webhook': {
        v10: '/mdpreview/Composable_CMS/content/Webhook.md',
        v11: '/mdpreview/Composable_CMS/content/Webhook.md',
        v12: '/mdpreview/Composable_CMS/content/Webhook.md',
        v13: '/v13/Composable_CMS/content/Webhook.md',
    },
})

export type FAAS_Content_TYPE_KEY = keyof typeof apiPath

export default apiPath