import { menuDataType } from "../LeftElement/menuData"

// v10
export const faasLoyaltyMenuDataV10: menuDataType[] = [
  {
      title: 'Loyalty',
      list: ['Loyalty Program','Loyalty Program Tier','Point Earn Action','Point Expire Setting','Point Redeem Action','Referral Setting','Referral Reward Rel','Reward','Consumer Point','Consumer Referral Rel','Consumer Reward History'],
  }
]

// v11
export const faasLoyaltyMenuDataV11: menuDataType[] = [
    {
        title: 'Loyalty',
        list: ['Loyalty Program','Loyalty Program Tier','Point Earn Action','Point Expire Setting','Point Redeem Action','Referral Setting','Referral Reward Rel','Reward','Consumer Point','Consumer Referral Rel','Consumer Reward History'],
    }
]

export const faasLoyaltyMenuDataV12: menuDataType[] = [
    {
        title: 'Loyalty',
        list: ['Loyalty Program','Loyalty Program Tier','Point Earn Action','Point Expire Setting','Point Redeem Action','Referral Setting','Referral Reward Rel','Reward','Consumer Point','Consumer Referral Rel','Consumer Reward History'],
    }
]

export const faasLoyaltyMenuDataV13: menuDataType[] = [
    {
        title: 'Loyalty',
        list: ['Loyalty Program','Loyalty Program Tier','Point Earn Action','Point Expire Setting','Point Redeem Action','Referral Setting','Referral Reward Rel','Reward','Consumer Point','Consumer Referral Rel','Consumer Reward History'],
    }
]
