import Cookies from "js-cookie"

export const removeToken = () => {
  Cookies.remove('rc_access_ticket', { domain: process.env.NODE_ENV == 'production' ? '.d2cgo.com' : 'localhost', path: '/' })
  Cookies.remove('rc_access_token', { path: '/' })
  Cookies.remove('rc_cliant', { domain: process.env.NODE_ENV == 'production' ? '.d2cgo.com' : 'localhost', path: '/' })
  Cookies.remove('rc-refreshToken', { path: '/' })
}


export const getDataFormSeamCookie = (value) => {
  return Cookies.get(value)
}
export const removeToSeamCookie = (value: string) => {
  Cookies.remove(value, { domain: process.env.NODE_ENV == 'production' ? '.d2cgo.com' : 'localhost', expires: 1000 * 60 * 60 * 24, path: '/' })
}

export const setToSeamLocalStorage = (name: string, data: string) => {
  localStorage.setItem(name, data)
}
