import { MultipleItems } from '../MultipleItems'

export const AMCarousel = () => {
  return (
    <>
       <div className="text-am26 font-bold text-am_333333  w-am1200 m-auto my-6">Your APIs</div>
      <MultipleItems />
    </>
  )
}
