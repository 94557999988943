import Slider from 'react-slick'
import styled from 'styled-components'
import Wrapper from '../../../../components/common/Wrapper'
import Left from '../../../../assets/image/Left.png'
import Right from '../../../../assets/image/Right.png'
import DownLoad from '../../../../assets/image/download.png'
import { Button } from '../ApiTag'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { SearchAtom } from '../../../../store/search'

const data = [
  {
    title: 'Product API',
    desc: 'Query and manage product data, including SPU, SKU, picture, specification, attribute and product classification',
  },
  {
    title: 'Consumer API',
    desc: 'Query and manage consumer data, including basic information, address, pet and registration',
  },
  {
    title: 'Order API',
    desc: 'Query and manage order information, operate order shipment, and configure basic order information',
  },
  {
    title: 'Subscription API',
    desc: 'Query and manage subscription information, and operate subscription update and shipment',
  },
  {
    title: 'Payment API',
    desc: 'Provide payment related configurations and payment process API',
  },
  {
    title: 'Inventory&Price API',
    desc: 'Basic information of the management platform, including users, roles, permissions, operation logs, etc',
  },
  {
    title: 'Marketing&Promotion API',
    desc: 'Manage the marketing activities, coupons, discounts and other information of the platform, and analyze and count the marketing results',
  },
  {
    title: 'Content API',
    desc: 'Content management and content directory management, free to create independent content web pages, including page layout, advertising, navigation, etc',
  },
  {
    title: 'Automation API',
    desc: 'Analyze and summarize platform data, generate statistical reports, and provide data for visualization',
  },
  {
    title: 'Store API',
    desc: 'Import and export of platform data, including product catalog, products, members, orders, subscriptions, etc',
  },
  {
    title: 'Message API',
    desc: '',
  },
  {
    title: 'Finance API',
    desc: '',
  },
  {
    title: 'Auth API',
    desc: '',
  },
]

const SampleNextArrow = styled.div`
  background: url(${Right}) center no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 40px;
`

const SamplePrevArrow = styled.div`
  background: url(${Left}) center no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 40px;
`

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
}

const MySlider = styled(Slider)`
  .slick-prev:before {
    content: '';
  }
  .slick-next:before {
    content: '';
  }
  .slick-slide {
    padding: 20px 0;
  }
  .slick-next:hover {
    background: url(${Right}) center no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 40px;
  }
  .slick-prev:hover {
    background: url(${Left}) center no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 40px;
  }
`
export const MultipleItems = () => {
  const navigate = useNavigate()
  const [info, setInfo] = useAtom(SearchAtom)

  return (
    <div className=" w-am1250 m-auto">
      <MySlider {...settings} ref={(slider) => console.log(slider)} className="m-auto">
        {data.map((item, index) => (
          <div key={index}>
            <Wrapper width="282px" height="248px" className="ml-am20">
              <div className="mt-am20 flex flex-row items-center w-am242 m-auto">
                <img src={DownLoad} alt="" style={{ width: 34, height: 28 }} />
                <div className="text-am20 text-am_333333 font-bold ">{item.title}</div>
              </div>
              <div className="text-am16 text-am_666666 text-left mt-am25 mb-am30 w-am242 m-auto h-am77 line-clamp-3 ">
                {item.desc}
              </div>
              <Button
                className="m-auto"
                onClick={() => {
                  setInfo({ ...info, documentType: 'Home' })
                  navigate('/console/apis/api-details', {
                    replace: false,
                    state: { name: item.title, desc: item.desc },
                  })
                }}
              >
                API Details
              </Button>
            </Wrapper>
          </div>
        ))}
      </MySlider>
    </div>
  )
}
