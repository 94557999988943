import { HttpApiCatalogType } from '../RightElement';
import { SE_TYPE_KEY } from './apiPath';

type TScListItemData = {
  label: SE_TYPE_KEY;
  desc: string;
}

type TScListData = {
  title: string;
  list: TScListItemData[];
}

export const settingData: TScListData[] = [
  {
    title: 'Quick Start',
    list: [
      {
        label: 'Setting Quick Start',
        desc: 'Setting Quick Start.',
      },
    ],
  },
  {
    title: 'Store',
    list: [
      {
        label: 'Store Details',
        desc: 'Store Details.',
      },
      {
        label: 'Basic Information',
        desc: 'Basic Information.',
      },
      {
        label: 'Region Language',
        desc: 'Region Language.',
      },
      {
        label: 'Currency',
        desc: 'Currency.',
      },
      {
        label: 'Standards and format',
        desc: 'Standards and format.',
      },
    ],
  },
  {
    title: 'Measurements',
    list: [
      {
        label: 'Measurement Family',
        desc: 'Measurement Family.',
      },
      {
        label: 'Measurement Unit',
        desc: 'Measurement Unit.',
      },
    ],
  },
  {
    title: 'Shipping Method',
    list: [
      {
        label: 'Transition Methods',
        desc: 'Transition Methods.',
      },
      {
        label: 'Shipping Methods',
        desc: 'Shipping Methods.',
      },
    ],
  },
]
