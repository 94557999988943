import { menuDataType } from "../LeftElement/menuData"

// v10
export const sellerCenterMenuDataV10: menuDataType[] = [
  {
      title: 'Dashboard',
      list: ['Dashboard'],
  },
  {
      title: 'Orders',
      list: ['Shipment&Order', 'Order Detail'],
  },
  {
      title: 'Subscription',
      list: ['Subscription List', 'Subscription Detail'],
  },
  {
      title: 'Products',
      list: ['Add Products', 'Product List', 'Search', 'Description', 'Attributes', 'Filter&Sort'],
  },
  {
      title: 'Consumer',
      list: ['Consumer List', 'Tagging Setting', 'Loyalty Programs', 'Points', 'Tier Management', 'Referrals', 'Reward History', 'Reward Settings'],
  },
  {
      title: 'Marketing Center',
      list: ['Voucher list', 'Live streaming list', 'Intelligent Recommendation'],
  },
  {
      title: 'Finance',
      list: ['Payment Setting'],
  },
  {
      title: 'Sales Channel',
      list: [
          'Points Of Sales',
          'Channel Options',

          'Account Management',
          'Fans Management',
          'Automatic Replies',
          'Reply Content',
          'Assets Management',
          'Menu Management',
          'QR Code Management',
          'Template Message',
          'Mini Program QR Code',
          'Mini Program Banner',
      ],
  },
  {
      title: 'Partner',
      list: ['Partner List', 'Partner Type'],
  },
]

// v11
export const sellerCenterMenuDataV11: menuDataType[] = [
  {
      title: 'Dashboard',
      list: ['Dashboard'],
  },
  {
      title: 'Orders',
      list: ['Shipment&Order', 'Order Detail'],
  },
  {
      title: 'Subscription',
      list: ['Subscription List', 'Subscription Detail'],
  },
  {
      title: 'Products',
      list: ['Add Products', 'Product List', 'Search', 'Description', 'Attributes', 'Filter&Sort'],
  },
  {
      title: 'Consumer',
      list: ['Consumer List', 'Tagging Setting', 'Loyalty Programs', 'Points', 'Tier Management', 'Referrals', 'Reward History', 'Reward Settings'],
  },
  {
      title: 'Marketing Center',
      list: ['Voucher list', 'Live streaming list', 'Intelligent Recommendation'],
  },
  {
      title: 'Finance',
      list: ['Payment Setting'],
  },
  {
      title: 'Sales Channel',
      list: [
          'Points Of Sales',
          'Channel Options',

          'Account Management',
          'Fans Management',
          'Automatic Replies',
          'Reply Content',
          'Assets Management',
          'Menu Management',
          'QR Code Management',
          'Template Message',
          'Mini Program QR Code',
          'Mini Program Banner',
      ],
  },
  {
      title: 'Partner',
      list: ['Partner List', 'Partner Type'],
  },
]


const recentlyVerison: menuDataType[] = [
  {
      title: 'Dashboard',
      list: ['Dashboard'],
  },
  {
      title: 'Orders',
      list: ['Shipment&Order', 'Order Detail', 'Order Setting'],
  },
  {
      title: 'Subscription',
      list: ['Subscription List', 'Subscription Detail', 'Subscription Setting'],
  },
  {
      title: 'Products',
      list: ['Add Products', 'Product List', 'Search', 'Description', 'Attributes', 'Filter&Sort'],
  },
  {
      title: 'Consumer',
      list: ['Consumer List', 'Tagging Setting', 'Loyalty Programs', 'Points', 'Tier Management', 'Referrals', 'Reward History', 'Reward Settings'],
  },
  {
      title: 'Marketing Center',
      list: ['Voucher list', 'Live streaming list', 'Intelligent Recommendation'],
  },
  {
      title: 'Finance',
      list: ['Payment Setting'],
  },
  {
      title: 'Sales Channel',
      list: [
          'Points Of Sales',
          'Channel Options',

          'Account Management',
          'Fans Management',
          'Automatic Replies',
          'Reply Content',
          'Assets Management',
          'Menu Management',
          'QR Code Management',
          'Template Message',
          'Mini Program QR Code',
          'Mini Program Banner',
          'Mini Program Banner',
          'Mini Program Message Template Management',
      ],
  },
  {
      title: 'Partner',
      list: ['Partner List', 'Partner Type'],
  },
]

// v16
export const sellerCenterMenuDataV16: menuDataType[] = recentlyVerison
// v17
export const sellerCenterMenuDataV17: menuDataType[] = recentlyVerison