import { menuDataType } from "../LeftElement/menuData"

// v10
export const scShopMenuDataV10: menuDataType[] = [
  {
      title: 'Product Catalog',
      list: ['Product'],
  },
  {
      title: 'Customers',
      list: ['Customer','Loyalty','Validation','Register','Contact'],
  },
  {
      title: 'Carts Orders & Shipping Information',
      list: ['Carts','Checkout','My order','Pay'],
  },
  {
      title: 'Content',
      list: ['Blog','Content value'],
  },
  {
      title: 'Configuration',
      list: ['Store'],
  }
]

// v11
export const scShopMenuDataV11: menuDataType[] = [
  {
      title: 'Product Catalog',
      list: ['Product'],
  },
  {
      title: 'Customers',
      list: ['Customer','Loyalty','Validation','Register','Contact'],
  },
  {
      title: 'Carts Orders & Shipping Information',
      list: ['Carts','Checkout','My order','Pay'],
  },
  {
      title: 'Content',
      list: ['Blog','Content value'],
  },
  {
      title: 'Configuration',
      list: ['Store'],
  }
]
