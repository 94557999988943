import { Pagination } from 'antd'
import styled from 'styled-components'
import { Catalog } from './components/Catalog'

const AMPagination = styled(Pagination)`
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }
`
export const Apis = () => {
  return (
    <>
      <Catalog />
      <div className=" w-am1200 m-auto text-right">
        <AMPagination total={10} showQuickJumper showSizeChanger={false} pageSizeOptions={[10]} />
      </div>
    </>
  )
}
