import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  // Loyalty 
  'Loyalty Program': {
    v10: '/mdpreview/Composable_Loyalty/Loyalty_Program.md',
    v11: '/mdpreview/Composable_Loyalty/Loyalty_Program.md',
    v12: '/mdpreview/Composable_Loyalty/Loyalty_Program.md',
    v13: '/v13/Composable_Loyalty/Loyalty_Program.md',
  },
  'Loyalty Program Tier': {
    v10: '/mdpreview/Composable_Loyalty/Loyalty_Program_Tier.md',
    v11: '/mdpreview/Composable_Loyalty/Loyalty_Program_Tier.md',
    v12: '/mdpreview/Composable_Loyalty/Loyalty_Program_Tier.md',
    v13: '/v13/Composable_Loyalty/Loyalty_Program_Tier.md',
  },
  'Point Earn Action': {
    v10: '/mdpreview/Composable_Loyalty/Point_Earn_Action.md',
    v11: '/mdpreview/Composable_Loyalty/Point_Earn_Action.md',
    v12: '/mdpreview/Composable_Loyalty/Point_Earn_Action.md',
    v13: '/v13/Composable_Loyalty/Point_Earn_Action.md',
  },
  'Point Expire Setting': {
    v10: '/mdpreview/Composable_Loyalty/Point_Expire_Setting.md',
    v11: '/mdpreview/Composable_Loyalty/Point_Expire_Setting.md',
    v12: '/mdpreview/Composable_Loyalty/Point_Expire_Setting.md',
    v13: '/v13/Composable_Loyalty/Point_Expire_Setting.md',
  },
  'Point Redeem Action': {
    v10: '/mdpreview/Composable_Loyalty/Point_Redeem_Action.md',
    v11: '/mdpreview/Composable_Loyalty/Point_Redeem_Action.md',
    v12: '/mdpreview/Composable_Loyalty/Point_Redeem_Action.md',
    v13: '/v13/Composable_Loyalty/Point_Redeem_Action.md',
  },
  'Referral Reward Rel': {
    v10: '/mdpreview/Composable_Loyalty/Referral_Reward_Rel.md',
    v11: '/mdpreview/Composable_Loyalty/Referral_Reward_Rel.md',
    v12: '/mdpreview/Composable_Loyalty/Referral_Reward_Rel.md',
    v13: '/v13/Composable_Loyalty/Referral_Reward_Rel.md',
  },
  'Referral Setting': {
    v10: '/mdpreview/Composable_Loyalty/Referral_Setting.md',
    v11: '/mdpreview/Composable_Loyalty/Referral_Setting.md',
    v12: '/mdpreview/Composable_Loyalty/Referral_Setting.md',
    v13: '/v13/Composable_Loyalty/Referral_Setting.md',
  },
  'Reward': {
    v10: '/mdpreview/Composable_Loyalty/Reward.md',
    v11: '/mdpreview/Composable_Loyalty/Reward.md',
    v12: '/mdpreview/Composable_Loyalty/Reward.md',
    v13: '/v13/Composable_Loyalty/Reward.md',
  },
  'Consumer Point': {
    v10: '/mdpreview/Composable_Loyalty/Consumer_Point.md',
    v11: '/mdpreview/Composable_Loyalty/Consumer_Point.md',
    v12: '/mdpreview/Composable_Loyalty/Consumer_Point.md',
    v13: '/v13/Composable_Loyalty/Consumer_Point.md',
  },
  'Consumer Referral Rel': {
    v10: '/mdpreview/Composable_Loyalty/Consumer_Referral_Rel.md',
    v11: '/mdpreview/Composable_Loyalty/Consumer_Referral_Rel.md',
    v12: '/mdpreview/Composable_Loyalty/Consumer_Referral_Rel.md',
    v13: '/v13/Composable_Loyalty/Consumer_Referral_Rel.md',
  },
  'Consumer Reward History': {
    v10: '/mdpreview/Composable_Loyalty/Consumer_Reward_History.md',
    v11: '/mdpreview/Composable_Loyalty/Consumer_Reward_History.md',
    v12: '/mdpreview/Composable_Loyalty/Consumer_Reward_History.md',
    v13: '/v13/Composable_Loyalty/Consumer_Reward_History.md',
  },
})

export type FAAS_Loyalty_TYPE_KEY = keyof typeof apiPath

export default apiPath