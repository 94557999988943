import {menuDataType} from "../LeftElement/menuData"

// v10
export const faasContentMenuDataV10: menuDataType[] = [

    {
        title: 'Content Type',
        list: ['Content Type'],
    },
    {
        title: 'Content',
        list: ['Content', 'Blog', 'Meta Data', 'Catalog', 'Assets', 'Webhook'],
    }
]

// v11
export const faasContentMenuDataV11: menuDataType[] = [
    {
        title: 'Content Type',
        list: ['Content Type'],
    },
    {
        title: 'Content',
        list: ['Content', 'Blog', 'Meta Data', 'Catalog', 'Assets', 'Webhook'],
    }
]

export const faasContentMenuDataV12: menuDataType[] = [
    {
        title: 'Content Type',
        list: ['Content Type'],
    },
    {
        title: 'Content',
        list: ['Content', 'Blog', 'Meta Data', 'Catalog', 'Assets', 'Webhook'],
    }
]

export const faasContentMenuDataV13: menuDataType[] = [
    {
        title: 'Content Type',
        list: ['Content Type'],
    },
    {
        title: 'Content',
        list: ['Content', 'Blog', 'Meta Data', 'Catalog', 'Assets', 'Webhook'],
    }
]
