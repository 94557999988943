import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AMCard } from './components/AMCard'
import { ConsoleElement } from './components/ConsoleElement'
import { DocumentElement } from './components/DocumentElement'

const Button = styled.div`
  width: 160px;
  height: 40px;
  background-color: var(--primary-color);
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
`

const tabElement = {
  Documentation: <DocumentElement />,
  Console: <ConsoleElement />,
} as const
export type TabElement = keyof typeof tabElement

export const ApiDetail = () => {
  const [activeTabKey, setActiveTabKey] = useState<TabElement>('Documentation')
  const { state } = useLocation()
  return (
    <>
      <div className="w-am1200 m-auto text-left">
        <div className="text-am26 font-bold mt-am60 mb-am6">{(state as Record<string, string>).name}</div>
        <div className="mb-am18">{(state as Record<string, string>).desc}</div>
        {/* <Button>Request Access</Button> */}
        <div className="flex flex-col">
          <AMCard getActiveTabKey={setActiveTabKey} />
          {tabElement[activeTabKey]}
        </div>
      </div>
    </>
  )
}
