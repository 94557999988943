import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  'Integration Portal': {
    v10: '/mdpreview/SC_Integration/Integration Portal.md',
    v17: '/v17/SC_Integration/Integration Portal.md',
  },
  
})

export type Integration_TYPE_KEY = keyof typeof apiPath

export default apiPath