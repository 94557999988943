import { menuDataType } from "../LeftElement/menuData"

// v10
export const faasMaMenuDataV10: menuDataType[] = [
  {
      title: 'MA Experience',
      list: ['Market Calender','Process','Template'],
  }
]

// v11
export const faasMaMenuDataV11: menuDataType[] = [
    {
        title: 'MA Experience',
        list: ['Market Calender','Process','Template'],
    }
]

export const faasMaMenuDataV12: menuDataType[] = [
    {
        title: 'MA Experience',
        list: ['Market Calender','Process','Template'],
    }
]

export const faasMaMenuDataV13: menuDataType[] = [
    {
        title: 'MA Experience',
        list: ['Market Calender','Process','Template'],
    }
]
