export default function() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#F1F6E6"/>
      <mask id="mask0_3823_14898" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
      <circle cx="28" cy="28" r="28" fill="#F1F6E6"/>
      </mask>
      <g mask="url(#mask0_3823_14898)">
      <rect x="17.1113" y="23.334" width="31.1111" height="40.4444" rx="2.8" fill="url(#paint0_linear_3823_14898)" fill-opacity="0.53"/>
      <rect x="9.33398" y="15.5557" width="34.2222" height="45.1111" rx="2.8" fill="url(#paint1_linear_3823_14898)"/>
      </g>
      <rect opacity="0.8" x="15.5557" y="23.334" width="12.4444" height="3.11111" rx="1.55556" fill="url(#paint2_linear_3823_14898)"/>
      <rect opacity="0.8" x="15.5557" y="29.5557" width="21.7778" height="3.11111" rx="1.55556" fill="url(#paint3_linear_3823_14898)"/>
      <rect opacity="0.8" x="16" y="36" width="21.7778" height="12.4444" rx="1.4" fill="url(#paint4_linear_3823_14898)"/>
      <defs>
      <linearGradient id="paint0_linear_3823_14898" x1="32.6669" y1="23.334" x2="32.6669" y2="63.7784" gradientUnits="userSpaceOnUse">
      <stop stop-color="#97BD50"/>
      <stop offset="1" stop-color="#C1DE8A"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3823_14898" x1="26.4451" y1="15.5557" x2="26.4451" y2="60.6668" gradientUnits="userSpaceOnUse">
      <stop stop-color="#97BD50"/>
      <stop offset="1" stop-color="#C1DE8A"/>
      </linearGradient>
      <linearGradient id="paint2_linear_3823_14898" x1="21.7779" y1="23.334" x2="21.7779" y2="26.4451" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F1F6E6"/>
      <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint3_linear_3823_14898" x1="26.4446" y1="29.5557" x2="26.4446" y2="32.6668" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F1F6E6"/>
      <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint4_linear_3823_14898" x1="26.8889" y1="36" x2="26.8889" y2="48.4444" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F1F6E6"/>
      <stop offset="1" stop-color="white"/>
      </linearGradient>
      </defs>
    </svg>
  )
}