import { menuDataType } from "../LeftElement/menuData"

// v10
export const faasCdpMenuDataV10: menuDataType[] = [
    {
        title: 'Consumer Experience',
        list: ['Consumer Insights','Event','Principal Attribute','Segment','Tag','Transaction Attribute'],
    }
]

// v11
export const faasCdpMenuDataV11: menuDataType[] = [
    {
        title: 'Consumer Experience',
        list: ['Consumer Insights','Event','Principal Attribute','Segment','Tag','Transaction Attribute'],
    }
]

export const faasCdpMenuDataV12: menuDataType[] = [
    {
        title: 'Consumer Experience',
        list: ['Consumer Insights','Event','Principal Attribute','Segment','Tag','Transaction Attribute'],
    }
]

export const faasCdpMenuDataV13: menuDataType[] = [
    {
        title: 'Consumer Experience',
        list: ['Consumer Insights','Event','Principal Attribute','Segment','Tag','Transaction Attribute'],
    }
]
