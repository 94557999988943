import ApiRoot from './fetcher'

export const userInfoByToken = async (input: any) => {
  let API_ROOT = 'https://api.dev.d2cgo.com';
  if (process.env.REACT_APP_ENV === 'prod') {
    API_ROOT = 'https://api.d2cgo.com';
  } else if (process.env.REACT_APP_ENV === 'aws_prd') {
    API_ROOT = 'https://api-eu-west-2-aws.d2cgo.com';
  }
  try {
    const data = await ApiRoot({ url: `${process.env.REACT_APP_AUTHPATH}/graphql` }).users().userAttestation(input)
    return data
  } catch (e) {
    return false
  }
}