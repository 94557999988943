import axios from "axios"

export const getTokenByTicktAndCliant = async (tikite: any, cliantId: any) => {
  let API_ROOT = 'https://api.dev.d2cgo.com';
  if (process.env.REACT_APP_ENV === 'prod') {
    API_ROOT = 'https://api.d2cgo.com';
  } else if (process.env.REACT_APP_ENV === 'aws_prd') {
    API_ROOT = 'https://api-eu-west-2-aws.d2cgo.com';
  }
  return await axios.post(`${process.env.REACT_APP_AUTHPATH}/exchangeToken`, {
//return await axios.post(`${API_ROOT}/auth/${process.env.REACT_APP_MAIN_VERSION}/displace-token`, {
    ticket: tikite,
    clientId: cliantId ? cliantId : '9c1d8cc0-c975-4fb5-991f-a7c652bee891'
  })
}