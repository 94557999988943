import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  'Quick Start': {
    v10: '/mdpreview/SC_CMS/quick start.md',
    v11: '/mdpreview/SC_CMS/quick start.md',
    v12: '/mdpreview/SC_CMS/quick start.md',
    v13: '/v13/SC_CMS/quick start.md',
  },
  'Collections': {
    v10: '/mdpreview/SC_CMS/collections.md',
    v11: '/mdpreview/SC_CMS/collections.md',
    v12: '/mdpreview/SC_CMS/collections.md',
    v13: '/v13/SC_CMS/collections.md',
  },
  'Content type': {
    v10: '/mdpreview/SC_CMS/content type.md',
    v11: '/mdpreview/SC_CMS/content type.md',
    v12: '/mdpreview/SC_CMS/content type.md',
    v13: '/v13/SC_CMS/content type.md',
    v16: '/v16/SC_CMS/content type.md',
    v17: '/v16/SC_CMS/content type.md',
  },
  'Themes': {
    v10: '/mdpreview/SC_CMS/themes.md',
    v11: '/mdpreview/SC_CMS/themes.md',
    v12: '/mdpreview/SC_CMS/themes.md',
    v16: '/v16/SC_CMS/themes.md',
    v17: '/v16/SC_CMS/themes.md',
  },
  'Pages': {
    v10: '/mdpreview/SC_CMS/pages.md',
    v11: '/mdpreview/SC_CMS/pages.md',
    v12: '/mdpreview/SC_CMS/pages.md',
    v13: '/v13/SC_CMS/pages.md',
    v16: '/v16/SC_CMS/pages.md',
    v17: '/v17/SC_CMS/pages.md',
  },
  'Posts': {
    v10: '/mdpreview/SC_CMS/posts.md',
    v11: '/mdpreview/SC_CMS/posts.md',
    v12: '/mdpreview/SC_CMS/posts.md',
    v13: '/v13/SC_CMS/posts.md',
    v16: '/v16/SC_CMS/posts.md',
    v17: '/v16/SC_CMS/posts.md',
  },
  'Webhook': {
    v10: '/mdpreview/SC_CMS/webhook.md',
    v11: '/mdpreview/SC_CMS/webhook.md',
    v12: '/mdpreview/SC_CMS/webhook.md',
    v13: '/v13/SC_CMS/webhook.md',
  },
})

export type CE_TYPE_KEY = keyof typeof apiPath

export default apiPath