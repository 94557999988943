import { menuDataType } from "../LeftElement/menuData"

// v10
export const scCEMenuDataV10: menuDataType[] = [
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
  {
    title: 'Posts',
    list: ['Posts'],
  },
  {
      title: 'Pages',
      list: ['Pages'],
  },
  {
      title: 'Collections',
      list: ['Collections'],
  },
  {
      title: 'Content type',
      list: ['Content type'],
  },
  
  {
      title: 'Webhook',
      list: ['Webhook'],
  }
]

// v11
export const scCEMenuDataV11: menuDataType[] = [
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
  {
      title: 'Posts',
      list: ['Posts'],
  },
  {
      title: 'Pages',
      list: ['Pages'],
  },
  {
      title: 'Collections',
      list: ['Collections'],
  },
  {
      title: 'Content type',
      list: ['Content type'],
  },
  
  {
      title: 'Webhook',
      list: ['Webhook'],
  }
]
const recentlyVerison: menuDataType[] = [ 
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
  {
    title: 'Themes',
    list: ['Themes'],
  },
  {
      title: 'Posts',
      list: ['Posts'],
  },
  {
      title: 'Pages',
      list: ['Pages'],
  },
  {
      title: 'Collections',
      list: ['Collections'],
  },
  {
      title: 'Content type',
      list: ['Content type'],
  },
  
  {
      title: 'Webhook',
      list: ['Webhook'],
  }
]


// v16
export const scCEMenuDataV16: menuDataType[] = recentlyVerison
// v17
export const scCEMenuDataV17: menuDataType[] = recentlyVerison