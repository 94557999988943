import { HttpApiCatalogType } from '../RightElement';
import { MA_TYPE_KEY } from './apiPath';

type TScListItemData = {
  label: MA_TYPE_KEY;
  desc: string;
}

type TScListData = {
  title: string;
  list: TScListItemData[];
}

export const integrationData: TScListData[] = [
  {
    title: 'Integration Portal',
    list: [
      {
        label: 'Integration Portal',
        desc: 'Integration Portal',
      },
    ],
  },
 
]