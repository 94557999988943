import { useLocation } from 'react-router'

type Path = keyof typeof path
const path: any = {
  'Consumer API': 'https://www.apifox.cn/apidoc/shared-f087baf6-2e4f-49ef-b78d-c3b1000eb62b',
  'Product API': 'https://www.apifox.cn/apidoc/shared-409272a1-8a2b-44b7-9291-73211c48f0af',
  'Order API': 'https://www.apifox.cn/apidoc/shared-3ef99a67-c48a-4ffd-80e6-298b54b6d9ff',
  // 'Finance API': 'https://www.apifox.cn/apidoc/shared-bcd54e05-7c93-4341-81db-9be8534c8071',
  'Payment API': 'https://www.apifox.cn/apidoc/shared-c93ee25f-df16-4ec5-a5c0-774f609629ee',
  'Marketing&Promotion API': 'https://www.apifox.cn/apidoc/shared-2935d95c-dd40-41d1-aee0-2398b4c49430',
  'Subscription API': 'https://www.apifox.cn/apidoc/shared-697c4450-ca33-435b-a7db-7fa2ce5cb6c0',

  'Inventory&Price API': 'https://www.apifox.cn/apidoc/shared-c93ee25f-df16-4ec5-a5c0-774f609629ee',
  'Content API': 'https://www.apifox.cn/apidoc/shared-2935d95c-dd40-41d1-aee0-2398b4c49430',
  'Automation API': 'https://www.apifox.cn/apidoc/shared-697c4450-ca33-435b-a7db-7fa2ce5cb6c0',
  'Store API': 'https://www.apifox.cn/apidoc/shared-c93ee25f-df16-4ec5-a5c0-774f609629ee',
  'Message API': 'https://www.apifox.cn/apidoc/shared-2935d95c-dd40-41d1-aee0-2398b4c49430',
  'Finance API': 'https://www.apifox.cn/apidoc/shared-697c4450-ca33-435b-a7db-7fa2ce5cb6c0',
}
export const ConsoleElement = () => {
  const { state } = useLocation()
  return (
    <div className="aspect-w-16 aspect-h-9">
      <iframe src={path[(state as Record<string, string>).name]} title="DTC" />
    </div>
  )
}
