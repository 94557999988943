import { Typography } from "antd";
import { LineChart, LineSeriesOption } from 'echarts/charts';
import {
    GridComponent,
    GridComponentOption,
    LegendComponent,
    LegendComponentOption, TitleComponent,
    TitleComponentOption,
    ToolboxComponent,
    ToolboxComponentOption,
    TooltipComponent,
    TooltipComponentOption
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useEffect, useRef } from "react";
const { Title } = Typography

type EChartsOption = echarts.ComposeOption<
| TitleComponentOption
| ToolboxComponentOption
| TooltipComponentOption
| GridComponentOption
| LegendComponentOption
| LineSeriesOption
>;

const Metrics = () => {
    const chartRef:any = useRef();

    const newEcharts = () => {
        echarts.use([
            TitleComponent,
            ToolboxComponent,
            TooltipComponent,
            GridComponent,
            LegendComponent,
            LineChart,
            CanvasRenderer,
            UniversalTransition
          ]);
          
          const myChart = echarts.init(chartRef.current);
          let option: EChartsOption;
          
          option = {
            title: {
              text: 'Stacked Line'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: 'Email',
                type: 'line',
                stack: 'Total',
                data: [120, 132, 101, 134, 90, 230, 210]
              },
              {
                name: 'Union Ads',
                type: 'line',
                stack: 'Total',
                data: [220, 182, 191, 234, 290, 330, 310]
              },
              {
                name: 'Video Ads',
                type: 'line',
                stack: 'Total',
                data: [150, 232, 201, 154, 190, 330, 410]
              },
              {
                name: 'Direct',
                type: 'line',
                stack: 'Total',
                data: [320, 332, 301, 334, 390, 330, 320]
              },
              {
                name: 'Search Engine',
                type: 'line',
                stack: 'Total',
                data: [820, 932, 901, 934, 1290, 1330, 1320]
              }
            ]
          };
          option && myChart.setOption(option);
    }

    useEffect(() => {
        newEcharts()
    }, [])

    return <div className="w-am1200 m-auto text-left">
        <Title className="title" style={{ marginBottom: '17px', marginTop: '50px' }} level={4}>
            Metrics
        </Title>
        <div ref={chartRef} className="w-full h-72" />
    </div>
}

export default Metrics