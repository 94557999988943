// @ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import query from 'query-string'
import axios from "axios";
import { userInfoByToken } from "@/framework/api/appclients";
import { useAtom } from 'jotai'
import { userAtom } from '@/store/user.store'
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { getDataFormSeamCookie, removeToken, removeToSeamCookie, setToSeamLocalStorage } from "@/utils/utils";
import Loading from '@/assets/images/loading2.gif'
import { getTokenByTicktAndCliant } from "@/framework/api/auth";
// import { languageMaps } from "@/locale";
// import { SiderContext } from '@/contexts/SiderContext'

// 当前登录界面分为两步。
// 如果当前url中带有code  则视为初始化登录，这个时候需要走原始路径 获取token refreshToken tickteCode 用户信息。
// 如果是内部跳转，则会带一个cliantID 这个时候代表已经登录过一次了。则需要拿着tikete和cliantid 去换取一个新的token
// 路径上带有token 则视为九宫格跳转过来的，需要将其存入cookie中，并且按照这个token去拿用户信息。
// 切勿单独修改一个工程，由于目前没有组件共享，修改后需要全量copy到其他工程

export const getcliantId = () => {
   return '9c1d8cc0-c975-4fb5-991f-a7c652bee891'
}
//用户信息全写localstore
export const setUserInfofn = async (token: any, cliantId: any) => {
    try {
        const useInfo = await userInfoByToken({ token: token, clientId: cliantId })
        if (!useInfo.user) return window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
        localStorage.setItem('rc-userInfo', JSON.stringify(useInfo.user))
        localStorage.setItem("rc_access_cliant", useInfo.associatedClientIds ?? [])
        // 首次登陆根绝后台语言来判断,如果存在是中文 或者英文中的其中一种。则选用，如果不存在则以 en-US为准
        if (!getDataFormSeamCookie('locale')) {
            // if (languageMaps[useInfo.user?.prefferedLanguage]) {
            //     setToSemeCookie('locale', languageMaps[useInfo.user?.prefferedLanguage])
            // }
        }
        if (useInfo.scope) {
            setToSeamLocalStorage('scope', JSON.stringify(useInfo.scope))
        } else {
            setToSeamLocalStorage('scope', JSON.stringify([]))
        }
        return useInfo.user
    } catch (err) {
        console.log(err)
    }
}
const AuthLoading = (props) => {
    const defaultCliantId = '9c1d8cc0-c975-4fb5-991f-a7c652bee891';
    const [, setUserInfo] = useAtom(userAtom)
    const setCollapsed = (flag?:any)=>{}
    // const { setCollapsed } = useContext(SiderContext)
    const navigator = useNavigate()
    const initPage = async () => {
        const querys = query.parse(window.location.search)
        const code = querys.code
        const redirect = querys.redirect
        if (code) { // 存在code 代表一个新的重定向登录。
            removeToSeamCookie('locale')
            removeToken()
        }
        const cliantId = querys.cliantId ?? ''
        const hasToken = Cookies.get('rc_access_token')
        const tikite = Cookies.get('rc_access_ticket')
        if (!hasToken) {
            let token = {}
            if (!tikite && code) {
                token = await axios.post(`${process.env.REACT_APP_AUTHPATH}/oauth/token`, {
                    code: code,
                    scope: '',
                    callbackUri: '',
                    clientId: cliantId ? cliantId : defaultCliantId,
                    authorizationType: "INTERNAL_SYSTEM_CODE"
                })
            } else if (tikite && cliantId) {
                token = await getTokenByTicktAndCliant(tikite, cliantId)
            } else {
                token = {}
            }
            if (token && token.data && token.data.isSuccess) {
                Cookies.set('rc-refreshToken', token.data.result.refreshToken, { path: '/', expires: 1000 * 60 * 60 * 24 })
                Cookies.set("rc_access_token", token.data.result.accessToken, { path: '/', expires: 1000 * 60 * 60 * 24 })
                Cookies.set("rc_access_ticket", token.data.result.ticket, { path: '/', expires: 1000 * 60 * 60 * 24 })
                Cookies.set("rc_cliant", cliantId ? cliantId : defaultCliantId, { path: '/', expires: 1000 * 60 * 60 * 24 })
                const user = await setUserInfofn(token.data.result.accessToken, cliantId ? cliantId : defaultCliantId)
                setUserInfo(user)
                if(redirect){
                    window.location.href = redirect;
                }else{
                    window.location.href = "/";
                }
            } else {
                window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
            }
        } else {
            const user = await setUserInfofn(hasToken, Cookies.get('rc_cliant'))
            setUserInfo(user)
            if (redirect) {
                window.location.href = redirect;
                return
            }
            navigator("/");
        }
    }
    const refreshPermistion = async (token, cliantId) => {
        const user = await setUserInfofn(token.toString().replace('_', ' '), cliantId)
        setUserInfo(user)
        navigator("/");
    }
    useEffect(() => {
        const querys = query.parse(window.location.search)
        const token = querys.token ?? ''
        const cliantId = querys.cliantId

        // 进入新系统切换需要重置左侧菜单收起状态
        // sessionStorage.removeItem('sider_collapsed')
        setCollapsed(true)

        if (token && cliantId) {
            Cookies.set("rc_access_token", token.toString().replace('_', ' '), { path: '/', expires: 1000 * 60 * 60 * 24 })
            refreshPermistion(token, cliantId)
        } else {
            initPage()
        }
    }, [])
    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <img className='mb-15' alt='' style={{ width: '100px' }} src={Loading} />
        </div>
    );
};
export default AuthLoading;

