import Wrapper from '../../../../components/common/Wrapper'
import styled from 'styled-components'
import PlatformAPIs from '../../../../assets/image/PlatformAPIs.png'
import SellerCenter from '../../../../assets/image/SellerCenter.png'
import MyTitle from '../MyTitle'
import { useNavigate } from 'react-router-dom'
import IconApi from '../Product/icons/IconApi'
import { ProductProps } from '../Product'
import { ReactNode } from 'react'
import { VERSIONS } from "../../../Detail"

const Title = styled.div`
  font-size: 22px;
  color: #000000;
`
const Content = styled.div`
  font-size: 16px;
  color: #666666;
  text-align: left;
  width: 331px;
  margin: 0 auto;
`
const Img = styled.img`
  width: 56px;
  height: 40px;
  margin: 40px auto 20px auto;
`

export const data: ProductProps[] = [
  {
    title: 'Commerce APIs',
    desc: 'API for building a perfect customer shopping experience',
    img: IconApi,
    key: 'doc_Shop',
  },
  {
    title: 'Content Experience APIs',
    desc: 'API provided for building a customized Content platform',
    img: IconApi,
    key: 'doc_Fass_Content',
  },
  {
    title: 'Consumer Experience APIs',
    desc: 'API provided for building a customized Consumer platform',
    img: IconApi,
    key: 'doc_Fass_CDP',
  },
  {
    title: 'MA Experience APIs',
    desc: 'API provided for building a customized marketing automation platform',
    img: IconApi,
    key: 'doc_Fass_MA',
  },
  {
    title: 'Fulfillment Experience APIs',
    desc: 'API provided for building a customized Fulfillment platform',
    img: IconApi,
    key: 'doc_Fulfillment',
  },
  {
    title: 'Loytal Experience APIs',
    desc: 'API provided for building a customized loytal platform',
    img: IconApi,
    key: 'doc_Fass_Loyalty',
  },
]
const ComposableCommerce = () => {
  const navigate = useNavigate()
  const latestVersion = VERSIONS[VERSIONS.length - 1].value
  return (
    <>
      <MyTitle>Composable Commerce</MyTitle>
      <div className="mb-6 text-lg">Best-in-class, standalone APIs that enable limitless commerce possibilities.</div>
      <div className="flex flex-row flex-wrap mt-2">
        {data.map((item) => (
          <Wrapper
            key={item.key}
            className="px-am20"
            width="384px"
            height="250px"
            onClick={() => navigate(`/${latestVersion}/detail/${item.key}`)}
          >
            <div className="flex justify-center mt-[30px] mb-[15px]">{item.img()}</div>
            <Title className="text-center">{item.title}</Title>
            <Content>{item.desc}</Content>
          </Wrapper>
        ))}
      </div>
    </>
  )
}

export default ComposableCommerce
