import { ceData } from './data'
import { useAtom } from 'jotai'
import { HttpApiCatalog } from '../../../../store/search'

export const SE_CE_MainPage = () => {
  const [info, setInfo] = useAtom(HttpApiCatalog)
  return (
    <div className="mt-am58 px-8 pb-8">
      <p className=" text-am16 font-normal mb-am60">
        Content Experience.
      </p>

      {ceData.map((item) => (
        <section key={item.title} className="mb-am60">
          <h2 className=" font-bold text-2xl">{item.title}</h2>
          <ul className="list-disc ml-am20">
            {item.list.map((child) => (
              <li key={child.label} className="text-base font-normal leading-6 mt-2 text-am_1a1a1a">
                <a href="javascript:;" onClick={() => setInfo({ name: child.label })} className=" underline ">
                  {child.label}
                </a>
                : {child.desc}
              </li>
            ))}
          </ul>
        </section>
      ))}
    </div>
  )
}
