import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  Checkout: {
    v10: '/mdpreview/API/Checkout.md',
    v11: '/mdpreview/API/Checkout.md',
    v12: '/mdpreview/API/Checkout.md',
    v13: '/v13/API/Checkout.md',
  },
  'Product Category': {
    v10: '/mdpreview/API/Product/sales_category.md',
    v11: '/mdpreview/API/Product/sales_category.md',
    v12: '/mdpreview/API/Product/sales_category.md',
    v13: '/v13/API/Product/sales_category.md',
  },
  'Shop Category': {
    v10: '/mdpreview/API/Product/shop_category.md',
    v11: '/mdpreview/API/Product/shop_category.md',
    v12: '/mdpreview/API/Product/shop_category.md',
    v13: '/v13/API/Product/shop_category.md',
  },
  Carts: {
    v10: '/mdpreview/API/ShoppingCart.md',
    v11: '/mdpreview/API/ShoppingCart.md',
    v12: '/mdpreview/API/ShoppingCart.md',
    v13: '/v13/API/ShoppingCart.md',
  },
  Product: {
    v10: '/mdpreview/API/regular_product.md',
    v11: '/mdpreview/API/regular_product.md',
    v12: '/mdpreview/API/regular_product.md',
    v13: '/v13/API/regular_product.md',
  },
  Recommendation: {
    v10: '/mdpreview/API/recommation.md',
    v11: '/mdpreview/API/recommation.md',
    v12: '/mdpreview/API/recommation.md',
    v13: '/v13/API/recommation.md',
  },
  'Product Search': {
    v10: '/mdpreview/API/Product/Product_Search.md',
    v11: '/mdpreview/API/Product/Product_Search.md',
    v12: '/mdpreview/API/Product/Product_Search.md',
    v13: '/v13/API/Product/Product_Search.md',
  },
  'Product ribbon': {
    v10: '/mdpreview/API/Product/product_ribbon.md',
    v11: '/mdpreview/API/Product/product_ribbon.md',
    v12: '/mdpreview/API/Product/product_ribbon.md',
    v13: '/v13/API/Product/product_ribbon.md',
  },
  'Product filter': {
    v10: '/mdpreview/API/Product/product_filter.md',
    v11: '/mdpreview/API/Product/product_filter.md',
    v12: '/mdpreview/API/Product/product_filter.md',
    v13: '/v13/API/Product/product_filter.md',
  },
  'Product sortBy': {
    v10: '/mdpreview/API/Product/product_sortBy.md',
    v11: '/mdpreview/API/Product/product_sortBy.md',
    v12: '/mdpreview/API/Product/product_sortBy.md',
    v13: '/v13/API/Product/product_sortBy.md',
  },
  Inventory: {
    v10: '/mdpreview/API/Product/inventory.md',
    v11: '/mdpreview/API/Product/inventory.md',
    v12: '/mdpreview/API/Product/inventory.md',
    v13: '/v13/API/Product/inventory.md',
  },
  'Product description': {
    v10: '/mdpreview/API/Product/product_description.md',
    v11: '/mdpreview/API/Product/product_description.md',
    v12: '/mdpreview/API/Product/product_description.md',
    v13: '/v13/API/Product/product_description.md',
  },

  Pay: {
    v10: '/mdpreview/API/Pay.md',
    v11: '/mdpreview/API/Pay.md',
    v12: '/mdpreview/API/Pay.md',
    v13: '/v13/API/Pay.md',
  },
  'My Order': {
    v10: '/mdpreview/API/Myorder.md',
    v11: '/mdpreview/API/Myorder.md',
    v12: '/mdpreview/API/Myorder.md',
    v13: '/v13/API/Myorder.md',
  },
  'Shipping Information': {
    v10: '/mdpreview/API/Shipping_information.md',
    v11: '/mdpreview/API/Shipping_information.md',
    v12: '/mdpreview/API/Shipping_information.md',
    v13: '/v13/API/Shipping_information.md',
  },
  'GraphQL API': {
    v10: '/mdpreview/API/GraphQL_API.md',
    v11: '/mdpreview/API/GraphQL_API.md',
    v12: '/mdpreview/API/GraphQL_API.md',
    v13: '/v13/API/GraphQL_API.md',
  },

  Marketing: {
    v10: '/mdpreview/API/marketing.md',
    v11: '/mdpreview/API/marketing.md',
    v12: '/mdpreview/API/marketing.md',
    v13: '/v13/API/marketing.md',
  },
  'Market Calendar': {
    v10: '/mdpreview/API/Ma/market_calendar.md',
    v11: '/mdpreview/API/Ma/market_calendar.md',
    v12: '/mdpreview/API/Ma/market_calendar.md',
    v13: '/v13/API/Ma/market_calendar.md',
  },
  Consumer: {
    v10: '/mdpreview/API/Consumer/consumer.md',
    v11: '/mdpreview/API/Consumer/consumer.md',
    v12: '/mdpreview/API/Consumer/consumer.md',
    v13: '/v13/API/Consumer/consumer.md',
  },
  Tax: {
    v10: '/mdpreview/API/order/tax.md',
    v11: '/mdpreview/API/order/tax.md',
    v12: '/mdpreview/API/order/tax.md',
    v13: '/v13/API/order/tax.md',
  },
  ShippingMethod: {
    v10: '/mdpreview/API/order/shipping_method.md',
    v11: '/mdpreview/API/order/shipping_method.md',
    v12: '/mdpreview/API/order/shipping_method.md',
    v13: '/v13/API/order/shipping_method.md',
  },
  Subscription: {
    v10: '/mdpreview/API/subscription/subscription.md',
    v11: '/mdpreview/API/subscription/subscription.md',
    v12: '/mdpreview/API/subscription/subscription.md',
    v13: '/v13/API/subscription/subscription.md',
  },


  'User': {
    v10: '/mdpreview/API/foundation/user.md',
    v11: '/mdpreview/API/foundation/user.md',
    v12: '/mdpreview/API/foundation/user.md',
    v13: '/v13/API/foundation/user.md',
  },
  'API Contract': {
    v10: '/mdpreview/API/API_Contract.md',
    v11: '/mdpreview/API/API_Contract.md',
    v12: '/mdpreview/API/API_Contract.md',
    v13: '/mdpreview/API/API_Contract.md',
  },

  'Errors': {
    v10: '/mdpreview/API/API_Errors.md',
    v11: '/mdpreview/API/API_Errors.md',
    v12: '/mdpreview/API/API_Errors.md',
    v13: '/v13/API/API_Errors.md',
  },
  'Consumer Pet': {
    v10: '/mdpreview/API/Consumer/consumerPet.md',
    v11: '/mdpreview/API/Consumer/consumerPet.md',
    v12: '/mdpreview/API/Consumer/consumerPet.md',
    v13: '/v13/API/Consumer/consumerPet.md',
  },
  'Consumer Tag': {
    v10: '/mdpreview/API/Consumer/consumerTag.md',
    v11: '/mdpreview/API/Consumer/consumerTag.md',
    v12: '/mdpreview/API/Consumer/consumerTag.md',
    v13: '/v13/API/Consumer/consumerTag.md',
  },
  'Consumer Address': {
    v10: '/mdpreview/API/Consumer/consumerAddress.md',
    v11: '/mdpreview/API/Consumer/consumerAddress.md',
    v12: '/mdpreview/API/Consumer/consumerAddress.md',
    v13: '/v13/API/Consumer/consumerAddress.md',
  },
  'Consumer Points': {
    v10: '/mdpreview/API/Consumer/consumer_point.md',
    v11: '/mdpreview/API/Consumer/consumer_point.md',
    v12: '/mdpreview/API/Consumer/consumer_point.md',
    v13: '/v13/API/Consumer/consumer_point.md',
  },
  'Loyalty Program': {
    v10: '/mdpreview/API/Consumer/Loyalty_Program.md',
    v11: '/mdpreview/API/Consumer/Loyalty_Program.md',
    v12: '/mdpreview/API/Consumer/Loyalty_Program.md',
    v13: '/v13/API/Consumer/Loyalty_Program.md',
  },
  'Loyalty Program Tier': {
    v10: '/mdpreview/API/Consumer/loyalty_program_tier.md',
    v11: '/mdpreview/API/Consumer/loyalty_program_tier.md',
    v12: '/mdpreview/API/Consumer/loyalty_program_tier.md',
    v13: '/v13/API/Consumer/loyalty_program_tier.md',
  },
  'Referral Reward Rel': {
    v10: '/mdpreview/API/Consumer/referral_reward_rel.md',
    v11: '/mdpreview/API/Consumer/referral_reward_rel.md',
    v12: '/mdpreview/API/Consumer/referral_reward_rel.md',
    v13: '/v13/API/Consumer/referral_reward_rel.md',
  },
  'Referral Setting': {
    v10: '/mdpreview/API/Consumer/referral_setting.md',
    v11: '/mdpreview/API/Consumer/referral_setting.md',
    v12: '/mdpreview/API/Consumer/referral_setting.md',
    v13: '/v13/API/Consumer/referral_setting.md',
  },
  'Referral Perk': {
    v10: '/mdpreview/API/Consumer/referral_perk.md',
    v11: '/mdpreview/API/Consumer/referral_perk.md',
    v12: '/mdpreview/API/Consumer/referral_perk.md',
    v13: '/v13/API/Consumer/referral_perk.md',
  },
  'Reward':  {
    v10: '/mdpreview/API/Consumer/Reward_History.md',
    v11: '/mdpreview/API/Consumer/Reward_History.md',
    v12: '/mdpreview/API/Consumer/Reward_History.md',
    v13: '/v13/API/Consumer/Reward_History.md',
  },
  'Recommend': {
    v10: '/mdpreview/API/subscription/recommend.md',
    v11: '/mdpreview/API/subscription/recommend.md',
    v12: '/mdpreview/API/subscription/recommend.md',
    v13: '/v13/API/subscription/recommend.md',
  },
  'Change password': {
    v10: '/mdpreview/API/foundation/changePassword.md',
    v11: '/mdpreview/API/foundation/changePassword.md',
    v12: '/mdpreview/API/foundation/changePassword.md',
    v13: '/v13/API/foundation/changePassword.md',
  },
  'Common Types': {
    v10: '/mdpreview/API/Common_Type.md',
    v11: '/mdpreview/API/Common_Type.md',
    v12: '/mdpreview/API/Common_Type.md',
    v13: '/v13/API/Common_Type.md',
  },
  'Store': {
    v10: '/mdpreview/API/store/store.md',
    v11: '/mdpreview/API/store/store.md',
    v12: '/mdpreview/API/store/store.md',
    v13: '/v13/API/store/store.md',
  },
  'Uom': {
    v10: '/mdpreview/API/Product/uom.md',
    v11: '/mdpreview/API/Product/uom.md',
    v12: '/mdpreview/API/Product/uom.md',
    v13: '/v13/API/Product/uom.md',
  },
  'Organization': {
    v10: "/mdpreview/API/foundation/organization.md",
    v11: "/mdpreview/API/foundation/organization.md",
    v12: "/mdpreview/API/foundation/organization.md",
    v13: "/v13/API/foundation/organization.md",
  },
  'Point Earn Action': {
    v10: "/mdpreview/API/Consumer/point_earn_action.md",
    v11: "/mdpreview/API/Consumer/point_earn_action.md",
    v12: "/mdpreview/API/Consumer/point_earn_action.md",
    v13: "/v13/API/Consumer/point_earn_action.md",
  },
  'Point Redeem Action': {
    v10: "/mdpreview/API/Consumer/point_redeem_action.md",
    v11: "/mdpreview/API/Consumer/point_redeem_action.md",
    v12: "/mdpreview/API/Consumer/point_redeem_action.md",
    v13: "/v13/API/Consumer/point_redeem_action.md",
  },
  'Point Expire Setting': {
    v10: "/mdpreview/API/Consumer/point_expire_setting.md",
    v11: "/mdpreview/API/Consumer/point_expire_setting.md",
    v12: "/mdpreview/API/Consumer/point_expire_setting.md",
    v13: "/v13/API/Consumer/point_expire_setting.md",
  },
  'Consumer Reward History': {
    v10: "/mdpreview/API/Consumer/consumer_reward_history.md",
    v11: "/mdpreview/API/Consumer/consumer_reward_history.md",
    v12: "/mdpreview/API/Consumer/consumer_reward_history.md",
    v13: "/v13/API/Consumer/consumer_reward_history.md",
  },

  'Message Record': {
    v10: '/mdpreview/API/Message/message_record.md',
    v11: '/mdpreview/API/Message/message_record.md',
    v12: '/mdpreview/API/Message/message_record.md',
    v13: '/v13/API/Message/message_record.md',
  },
  'Tag':{
    v10: '/mdpreview/API/CDP/tag.md',
    v11: '/mdpreview/API/CDP/tag.md',
    v12: '/mdpreview/API/CDP/tag.md',
    v13: '/v13/API/CDP/tag.md',
  },
  'Segment': {
    v10: '/mdpreview/API/CDP/segment.md',
    v11: '/mdpreview/API/CDP/segment.md',
    v12: '/mdpreview/API/CDP/segment.md',
    v13: '/v13/API/CDP/segment.md',
  },
  'Consumer Insight': {
    v10: '/mdpreview/API/CDP/consumer_insight.md',
    v11: '/mdpreview/API/CDP/consumer_insight.md',
    v12: '/mdpreview/API/CDP/consumer_insight.md',
    v13: '/v13/API/CDP/consumer_insight.md',
  },

  // Workflow
  'Activity Log': {
    v10: '/mdpreview/API/workflow/activity_log.md',
    v11: '/mdpreview/API/workflow/activity_log.md',
    v12: '/mdpreview/API/workflow/activity_log.md',
    v13: '/v13/API/workflow/activity_log.md',
  },
})

export type API_TYPE_KEY = keyof typeof apiPath

export default apiPath