import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  // CDP
  'Consumer Insights': {
    v10: '/mdpreview/Composable_CDP/Consumer_Insight.md',
    v11: '/mdpreview/Composable_CDP/Consumer_Insight.md',
    v12: '/mdpreview/Composable_CDP/Consumer_Insight.md',
    v13: '/v13/Composable_CDP/Consumer_Insight.md',
  },
  'Event': {
    v10: '/mdpreview/Composable_CDP/Event.md',
    v11: '/mdpreview/Composable_CDP/Event.md',
    v12: '/mdpreview/Composable_CDP/Event.md',
    v13: '/v13/Composable_CDP/Event.md',
  },
  'Principal Attribute': {
    v10: '/mdpreview/Composable_CDP/Principal_Attribute.md',
    v11: '/mdpreview/Composable_CDP/Principal_Attribute.md',
    v12: '/mdpreview/Composable_CDP/Principal_Attribute.md',
    v13: '/v13/Composable_CDP/Principal_Attribute.md',
  },
  'Segment': {
    v10: '/mdpreview/Composable_CDP/Segment.md',
    v11: '/mdpreview/Composable_CDP/Segment.md',
    v12: '/mdpreview/Composable_CDP/Segment.md',
    v13: '/v13/Composable_CDP/Segment.md',
  },
  'Tag': {
    v10: '/mdpreview/Composable_CDP/Tag.md',
    v11: '/mdpreview/Composable_CDP/Tag.md',
    v12: '/mdpreview/Composable_CDP/Tag.md',
    v13: '/v13/Composable_CDP/Tag.md',
  },
  'Transaction Attribute': {
    v10: '/mdpreview/Composable_CDP/Transaction_Attribute.md',
    v11: '/mdpreview/Composable_CDP/Transaction_Attribute.md',
    v12: '/mdpreview/Composable_CDP/Transaction_Attribute.md',
    v13: '/v13/Composable_CDP/Transaction_Attribute.md',
  },
})

export type FAAS_CDP_TYPE_KEY = keyof typeof apiPath

export default apiPath