import Wrapper from '../../../../components/common/Wrapper'
import styled from 'styled-components'
import PlatformAPIs from '../../../../assets/image/PlatformAPIs.png'
import SellerCenter from '../../../../assets/image/SellerCenter.png'
import MyTitle from '../MyTitle'
import { useNavigate } from 'react-router-dom'
import IconApi from './icons/IconApi'
import IconConsumer from './icons/IconConsumer'
import IconContent from './icons/IconContent'
import IconMa from './icons/IconMa'
import IconSale from './icons/IconSale'
import IconSetting from './icons/IconSetting'
import IconIntegration from './icons/IconIntegration'

import { ReactNode } from 'react'
import { VERSIONS } from "../../../Detail"

const Title = styled.div`
  font-size: 22px;
  color: #000000;
`
const Content = styled.div`
  font-size: 16px;
  color: #666666;
  text-align: left;
  width: 331px;
  margin: 0 auto;
`
const Img = styled.img`
  width: 56px;
  height: 40px;
  margin: 40px auto 20px auto;
`
export type ProductProps = {
  title: string
  desc: string
  img: () => ReactNode
  key: string
}
export const data: ProductProps[] = [
  {
    title: 'Sales Experience',
    desc: 'The User Interface to manage your business',
    img: IconSale,
    key: 'SellerCenter',
  },
  {
    title: 'Content Experience',
    desc: 'Content Experience is a modern apprach that provide flexibility that solves all of content needs',
    img: IconContent,
    key: 'doc_CE',
  },
  {
    title: 'Consumer Experience',
    desc: 'Consumer experience portal is to collect and unify consumer data from multiple sources to build a single, coherent, complete view and then segment consumers.',
    img: IconConsumer,
    key: 'doc_CDP',
  },
  {
    title: 'MA Experience',
    desc: 'MA Portal helps the brand utilize current consumer segments, and automatically trigger the conversion opportunities of additional sales and cross-sales.',
    img: IconMa,
    key: 'doc_MA',
  },
  {
    title: 'Global Setting',
    desc: 'The User Interface to manage your business',
    img: IconSetting,
    key: 'doc_Setting',
  },
  {
    title: 'Integration Portal',
    desc: 'A comprehensive platform acts as a centralized hub, providing real-time visibility into the status, performance, and health of all integrated systems.',
    img: IconIntegration,
    key: 'doc_Integration',
  },
]
const Product = () => {
  const navigate = useNavigate()
  const latestVersion = VERSIONS[VERSIONS.length - 1].value
  return (
    <>
      <MyTitle>Our Product</MyTitle>
      <div className="mb-6 text-lg">The workflows and tools to deliver outstanding customer-facing experiences.</div>
      <div className="flex flex-row flex-wrap mt-2">
        {data.map((item) => (
          <Wrapper
            key={item.key}
            className="px-am20"
            width="384px"
            height="250px"
            onClick={() => navigate(`/${latestVersion}/detail/${item.key}`)}
          >
            <div className="flex justify-center mt-[30px] mb-[15px]">{item.img()}</div>
            <Title className="text-center">{item.title}</Title>
            <Content>{item.desc}</Content>
          </Wrapper>
        ))}
      </div>
    </>
  )
}

export default Product
