import { menuDataType } from "../LeftElement/menuData"

// v10
export const scSettingMenuDataV10: menuDataType[] = [
  {
      title: 'Quick Start',
      list: ['Setting Quick Start'],
  },
  {
      title: 'Store',
      list: ['Store Details','Basic Information','Region Language','Currency','Standards and format'],
  },
  {
      title: 'Measurements',
      list: ['Measurement Family','Measurement Unit'],
  },
  {
      title: 'Shipping Method',
      list: ['Transition Methods','Shipping Methods'],
  },
  {
      title: 'Configuration',
      list: ['Store'],
  }
]

// v11
export const scSettingMenuDataV11: menuDataType[] = [
  {
      title: 'Quick Start',
      list: ['Setting Quick Start'],
  },
  {
      title: 'Store',
      list: ['Store Details','Basic Information','Region Language','Currency','Standards and format'],
  },
  {
      title: 'Measurements',
      list: ['Measurement Family','Measurement Unit'],
  },
  {
      title: 'Shipping Method',
      list: ['Transition Methods','Shipping Methods'],
  },
  {
      title: 'Configuration',
      list: ['Store'],
  }
]

// v17
export const scSettingMenuDataV17: menuDataType[] = [
  {
      title: 'Quick Start',
      list: ['Setting Quick Start'],
  },
  {
      title: 'Store',
      list: ['Store Details','Basic Information','Region Language','Currency','Standards and format'],
  },
  {
      title: 'Measurements',
      list: ['Measurement Family','Measurement Unit'],
  },
  {
      title: 'Shipping Method',
      list: ['Transition Methods','Shipping Methods'],
  },
  {
      title: 'Configuration',
      list: ['Store'],
  },
  {
    title: 'Translation',
    list: ['Translation'],
}
]
