export default function IconSetting() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#F1F6E6"/>
      <rect x="37.334" y="15.5557" width="7.77778" height="14" rx="1" fill="url(#paint0_linear_3823_14840)" fill-opacity="0.4"/>
      <mask id="mask0_3823_14840" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
      <circle cx="28" cy="28" r="28" fill="#E9F4FF"/>
      </mask>
      <g mask="url(#mask0_3823_14840)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M49.4497 30.9993L30.0251 13.1937C29.4993 12.7117 28.8119 12.4443 28.0985 12.4443C27.3852 12.4443 26.6978 12.7117 26.172 13.1937L6.74938 30.9993C6.508 31.2211 6.33917 31.5107 6.26495 31.83C6.19074 32.1493 6.21458 32.4836 6.33338 32.7892C6.45217 33.0947 6.66038 33.3574 6.9308 33.5427C7.20123 33.7281 7.52127 33.8275 7.84911 33.8281H8.62644C10.1728 33.8281 11.4264 35.0817 11.4264 36.6281V51.9271C11.4264 52.462 11.5318 52.9916 11.7365 53.4858C11.9412 53.98 12.2412 54.429 12.6194 54.8072C12.9976 55.1854 13.4467 55.4855 13.9408 55.6901C14.435 55.8948 14.9647 56.0002 15.4995 56.0002H40.6975C41.2324 56.0002 41.7621 55.8948 42.2563 55.6901C42.7504 55.4855 43.1994 55.1854 43.5777 54.8072C43.9559 54.429 44.2559 53.98 44.4606 53.4858C44.6653 52.9916 44.7707 52.462 44.7707 51.9271V36.6297C44.7707 35.0826 46.0252 33.8288 47.5722 33.8297L48.35 33.8301C48.6783 33.83 48.9989 33.7307 49.2698 33.5453C49.5407 33.3598 49.7492 33.0969 49.8681 32.7908C49.987 32.4848 50.0107 32.1501 49.936 31.8304C49.8613 31.5107 49.6919 31.221 49.4497 30.9993Z" fill="url(#paint1_linear_3823_14840)"/>
      </g>
      <path d="M21.6472 30.1893C22.6833 29.1977 23.9276 28.4498 25.2894 28C25.9678 28.9329 27.0684 29.5395 28.3105 29.5395C29.5526 29.5395 30.653 28.9329 31.3315 28C32.6933 28.4498 33.9376 29.1977 34.9737 30.1893C34.5364 31.229 34.5734 32.454 35.1809 33.5062C35.7574 34.5047 36.7254 35.1361 37.779 35.3184C37.8963 35.9258 37.9552 36.5431 37.9549 37.1618C37.9549 38.0245 37.8417 38.8606 37.6292 39.6562C36.6341 39.8706 35.7298 40.4885 35.1809 41.4395C34.934 41.8659 34.7742 42.337 34.7109 42.8256C34.6475 43.3142 34.6817 43.8105 34.8116 44.2857C33.6969 45.3049 32.3581 46.0475 30.9034 46.4536C30.2082 45.7806 29.2781 45.4049 28.3105 45.4062C27.3428 45.4048 26.4126 45.7805 25.7174 46.4536C24.2627 46.0475 22.9239 45.3049 21.8093 44.2857C21.9392 43.8105 21.9735 43.3142 21.9101 42.8256C21.8467 42.337 21.6869 41.8659 21.44 41.4395C20.8909 40.4886 19.9868 39.8706 18.9916 39.6562C18.7748 38.8425 18.6653 38.0039 18.666 37.1618C18.666 36.5314 18.7265 35.9151 18.842 35.3184C19.8955 35.1361 20.8636 34.5047 21.44 33.5062C22.0475 32.454 22.0845 31.2289 21.6472 30.1893ZM28.3105 41.8284C30.8879 41.8284 32.9771 39.7392 32.9771 37.1618C32.9771 34.5844 30.8879 32.4951 28.3105 32.4951C25.7331 32.4951 23.6438 34.5844 23.6438 37.1618C23.6438 39.7392 25.7331 41.8284 28.3105 41.8284ZM28.3105 39.4951C26.9358 39.4951 25.8216 38.3808 25.8216 37.0062C25.8216 35.6316 26.9358 34.5173 28.3105 34.5173C29.6851 34.5173 30.7994 35.6316 30.7994 37.0062C30.7994 38.3808 29.6851 39.4951 28.3105 39.4951Z" fill="url(#paint2_linear_3823_14840)" fill-opacity="0.77"/>
      <defs>
      <linearGradient id="paint0_linear_3823_14840" x1="41.2229" y1="15.5557" x2="41.2229" y2="29.5557" gradientUnits="userSpaceOnUse">
      <stop stop-color="#97BD50"/>
      <stop offset="1" stop-color="#C1DE8A"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3823_14840" x1="28.1007" y1="12.4443" x2="28.1007" y2="56.0002" gradientUnits="userSpaceOnUse">
      <stop stop-color="#97BD50"/>
      <stop offset="1" stop-color="#C1DE8A"/>
      </linearGradient>
      <linearGradient id="paint2_linear_3823_14840" x1="28.3105" y1="28" x2="28.3105" y2="46.4536" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F1F6E6"/>
      <stop offset="1" stop-color="white"/>
      </linearGradient>
      </defs>
    </svg>
  )
}