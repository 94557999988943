import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { VERSIONS } from "../../../views/Detail"
// import LOGO_black from '../../../assets/image/LOGO_black.png'
import LOGO_black from '../../../assets/image/logo-black.png'

type WrapperProps = {
  width: number
}
const Wrapper = styled.div<WrapperProps>`
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  width: ${(props) => `${props.width}px` || ''};
  /* padding: 20px 7% 0 7%; */
  padding-top: 20px;
  margin: 0 auto;
`
const Logo = styled.img`
  height: 48px;
  width: 48px;
  margin: 0 auto;
`
const Developer = ['HTTP API', 'GraphQL API', 'Platform Release Notes', 'SDKs & Client Libraries']
const MerchantCenter = ['Documentation', 'Release Notes']
const SignUp = ['Support']
type FooterProps = {
  width: number
}
const Footer = ({ width }: FooterProps) => {
  const navigate = useNavigate()
  const latestVersion = VERSIONS[VERSIONS.length - 1].value
  return (
    <div className="bg-gray-600">
      <Wrapper width={width}>
        <div className="text-center mr-am_5">
          <Logo src={LOGO_black} />
          <div className="text-am_373737 mt-2 whitespace-nowrap">DTC Open Platform</div>
        </div>
        <div className="text-am_333333 text-am12 text-left mr-am_5">
          <p className="font-bold">Developer Center</p>
          <p className="cursor-pointer hover:underline" onClick={() => navigate('/console/search')}>
            HTTP API
          </p>
          <p className="cursor-pointer hover:underline" onClick={() => navigate(`/${latestVersion}/detail/PlatformAPIs`)}>
            GraphQL API
          </p>
          <p className="cursor-pointer hover:underline" onClick={() => navigate("/#releasenote")}>
            Platform Release Notes
          </p>
          <p className="cursor-pointer hover:underline">
            SDKs & Client Libraries
          </p>
          {/* {Developer.map((item) => (
            <p key={item}>
              {item}
              {item === 'Custom Applications' && (
                <span className="text-am_058CDF bg-blue-500  p-1 rounded-sm">BETA</span>
              )}
            </p>
          ))} */}
        </div>
        <div className="text-am_333333 text-am12 text-left mr-am_33">
          <p className=" font-bold">Merchant Center</p>
          <p className="cursor-pointer hover:underline" onClick={() => navigate(`/${latestVersion}/detail/SellerCenter`)}>
            Documentation
          </p>
          <p className="cursor-pointer hover:underline" onClick={() => navigate("/#releasenote")}>
            Release Notes
          </p>
          {/* {MerchantCenter.map((item) => (
            <p key={item}>{item}</p>
          ))} */}
        </div>
        <div className="text-am_333333 text-am12 text-left mr-20 border-l border-gray-400 pl-10 ">
          <p className="cursor-pointer hover:underline" onClick={() => window.open("https://dtcplatform.atlassian.net/servicedesk/customer/portals", undefined, "noopener")}>
            Support
          </p>
          {/* {SignUp.map((item) => (
            <p key={item}>{item}</p>
          ))} */}
        </div>
      </Wrapper>
      <div className={`border-t border-gray-400 w-am${width}  m-auto`} />
      <div className={`flex  justify-between text-am12 text-am_333333 w-am${width}  py-2  m-auto`}>
        <span>Copyright © 2023 DTC Open Platform</span>
        <span>
          <span className="border-r border-gray-400 pr-2">Privacy Policy</span>
          <span className="ml-2">Imprint</span>
        </span>
      </div>
    </div>
  )
}

export default Footer
