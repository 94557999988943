import { HttpApiCatalogType } from '../RightElement';
import { MA_TYPE_KEY } from './apiPath';

type TScListItemData = {
  label: MA_TYPE_KEY;
  desc: string;
}

type TScListData = {
  title: string;
  list: TScListItemData[];
}

export const maData: TScListData[] = [
  {
    title: 'OverView',
    list: [
      {
        label: 'Overview',
        desc: 'Displays important MA features and shortcuts.',
      },
    ],
  },
  {
    title: 'Quick Start',
    list: [
      {
        label: 'Quick Start',
        desc: 'Displays important MA features and shortcuts.',
      },
    ],
  },
  {
    title: 'Campaign Plan',
    list: [
      {
        label: 'Campaign Plan',
        desc: 'Displays important MA features and shortcuts.',
      },
    ],
  },
  {
    title: 'Smart Operation',
    list: [
      {
        label: 'Process Canvas',
        desc: 'Displays important MA features and shortcuts.',
      },
      {
        label: 'Quick Task',
        desc: 'Displays important MA features and shortcuts.',
      },
    ],
  },
  {
    title: 'Templates',
    list: [
      {
        label: 'Email Template',
        desc: 'Displays important MA features and shortcuts.',
      },
      {
        label: 'SMS Template',
        desc: 'Displays important MA features and shortcuts.',
      },
    ],
  },
  {
    title: 'Message Center',
    list: [
      {
        label: 'Message List',
        desc: 'Displays important MA features and shortcuts.',
      },
    ],
  },
]