import {menuDataType} from "../LeftElement/menuData"

// v10
export const faasFulfillmentMenuDataV10: menuDataType[] = [
    {
        title: 'Orders',
        list: ['Order', 'Inbound', 'Outbound'],
    },
    {
        title: 'Outbound&Inbound',
        list: ['Inbound', 'Outbound'],
    },
    {
        title: 'Warehouse Inventory',
        list: ['Inventory'],
    },
    {
        title: 'Fulfillment',
        list: ['Inventory Network', 'Sourcing Rule'],
    }
]

// v11
export const faasFulfillmentMenuDataV11: menuDataType[] = [
    {
        title: 'Orders',
        list: ['Order', 'Inbound', 'Outbound'],
    },
    {
        title: 'Warehouse Inventory',
        list: ['Inventory'],
    },
    {
        title: 'Fulfillment',
        list: ['Inventory Network', 'Sourcing Rule'],
    }
]

export const faasFulfillmentMenuDataV12: menuDataType[] = [
    {
        title: 'Orders',
        list: ['Order', 'Inbound', 'Outbound'],
    },
    {
        title: 'Warehouse Inventory',
        list: ['Inventory'],
    },
    {
        title: 'Fulfillment',
        list: ['Inventory Network', 'Sourcing Rule'],
    }
]

export const faasFulfillmentMenuDataV13: menuDataType[] = [
    {
        title: 'Orders',
        list: ['Order', 'Inbound', 'Outbound'],
    },
    {
        title: 'Warehouse Inventory',
        list: ['Inventory'],
    },
    {
        title: 'Fulfillment',
        list: ['Inventory Network', 'Sourcing Rule'],
    }
]
