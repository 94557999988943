import { Card } from 'antd'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { SearchAtom } from '../../../../../store/search'
import { useState } from 'react'
import { TabElement } from '../../../Detail'

const tabList = [
  {
    key: 'Documentation',
    tab: 'API Documentation',
  },
  {
    key: 'Console',
    tab: 'API Console',
  },
]

const MyCardTab = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  .ant-card-head {
    padding: 0;
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    font-weight: 500;
  }
  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .ant-tabs-tab-active {
    border-bottom: 1px solid;
  }
`
type AMCardProps = {
  getActiveTabKey: (val: TabElement) => void
}
export const AMCard = ({ getActiveTabKey }: AMCardProps) => {
  const [info, setInfo] = useAtom(SearchAtom)
  const [activeTabKey, setactiveTabKey] = useState('Documentation')
  const contentList: Record<string, React.ReactNode> = {}

  return (
    <MyCardTab
      style={{ width: 273 }}
      tabList={tabList}
      bordered={false}
      activeTabKey={activeTabKey}
      onTabChange={(key: any) => {
        setactiveTabKey(key)
        setInfo({ ...info, activeTabKey: key })
        getActiveTabKey(key)
      }}
    >
      {contentList[activeTabKey]}
    </MyCardTab>
  )
}
