import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  // Customers
  'Customer': {
    v10: '/mdpreview/Composable_Shop/customers/Customer.md',
    v11: '/mdpreview/Composable_Shop/customers/Customer.md',
    v12: '/mdpreview/Composable_Shop/customers/Customer.md',
    v13: '/v13/Composable_Shop/customers/Customer.md',
  },
  'Loyalty': {
    v10: '/mdpreview/Composable_Shop/customers/Loyalty.md',
    v11: '/mdpreview/Composable_Shop/customers/Loyalty.md',
    v12: '/mdpreview/Composable_Shop/customers/Loyalty.md',
    v13: '/v13/Composable_Shop/customers/Loyalty.md',
  },
  'Validation': {
    v10: '/mdpreview/Composable_Shop/customers/Validation.md',
    v11: '/mdpreview/Composable_Shop/customers/Validation.md',
    v12: '/mdpreview/Composable_Shop/customers/Validation.md',
    v13: '/v13/Composable_Shop/customers/Validation.md',
  },
  'Register': {
    v10: '/mdpreview/Composable_Shop/customers/Register.md',
    v11: '/mdpreview/Composable_Shop/customers/Register.md',
    v12: '/mdpreview/Composable_Shop/customers/Register.md',
    v13: '/v13/Composable_Shop/customers/Register.md',
  },
  'Contact': {
    v10: '/mdpreview/Composable_Shop/customers/Contact.md',
    v11: '/mdpreview/Composable_Shop/customers/Contact.md',
    v12: '/mdpreview/Composable_Shop/customers/Contact.md',
    v13: '/v13/Composable_Shop/customers/Contact.md',
  },

  // Carts Orders & Shipping Information
  'Carts': {
    v10: '/mdpreview/Composable_Shop/carts_orders_shipping/Carts.md',
    v11: '/mdpreview/Composable_Shop/carts_orders_shipping/Carts.md',
    v12: '/mdpreview/Composable_Shop/carts_orders_shipping/Carts.md',
    v13: '/v13/Composable_Shop/carts_orders_shipping/Carts.md',
  },
  'Checkout': {
    v10: '/mdpreview/Composable_Shop/carts_orders_shipping/Checkout.md',
    v11: '/mdpreview/Composable_Shop/carts_orders_shipping/Checkout.md',
    v12: '/mdpreview/Composable_Shop/carts_orders_shipping/Checkout.md',
    v13: '/v13/Composable_Shop/carts_orders_shipping/Checkout.md',
  },
  'My order': {
    v10: '/mdpreview/Composable_Shop/carts_orders_shipping/My_order.md',
    v11: '/mdpreview/Composable_Shop/carts_orders_shipping/My_order.md',
    v12: '/mdpreview/Composable_Shop/carts_orders_shipping/My_order.md',
    v13: '/v13/Composable_Shop/carts_orders_shipping/My_order.md',
  },
  'Pay': {
    v10: '/mdpreview/Composable_Shop/carts_orders_shipping/Pay.md',
    v11: '/mdpreview/Composable_Shop/carts_orders_shipping/Pay.md',
    v12: '/mdpreview/Composable_Shop/carts_orders_shipping/Pay.md',
    v13: '/v13/Composable_Shop/carts_orders_shipping/Pay.md',
  },
  'Blog': {
    v10: '/mdpreview/Composable_Shop/content/blog.md',
    v11: '/mdpreview/Composable_Shop/content/blog.md',
    v12: '/mdpreview/Composable_Shop/content/blog.md',
    v13: '/v13/Composable_Shop/content/blog.md',

  },
  'Content value': {
    v10: '/mdpreview/Composable_Shop/content/contentValue.md',
    v11: '/mdpreview/Composable_Shop/content/contentValue.md',
    v12: '/mdpreview/Composable_Shop/content/contentValue.md',
    v13: '/v13/Composable_Shop/content/contentValue.md',
  },

  //Product Catalog
  'Product': {
    v10: '/mdpreview/Composable_Shop/product/Product.md',
    v11: '/mdpreview/Composable_Shop/product/Product.md',
    v12: '/mdpreview/Composable_Shop/product/Product.md',
    v13: '/v13/Composable_Shop/product/Product.md',
  },

  //Configuration
  'Store': {
    v10: '/mdpreview/Composable_Shop/configuration/store.md',
    v11: '/mdpreview/Composable_Shop/configuration/store.md',
    v12: '/mdpreview/Composable_Shop/configuration/store.md',
    v13: '/v13/Composable_Shop/configuration/store.md',
  },
})

export type Shop_TYPE_KEY = keyof typeof apiPath

export default apiPath