import { useState } from "react"
import { useAtom } from 'jotai'
import { useSelections } from 'ahooks'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import icon from '../../../../assets/image/icon.png'
import LOGO_black from '../../../../assets/image/logo-black.png'
// import notes from '../../../../assets/image/notes.png'
import { Select } from "antd"
import { HttpApiCatalog } from '../../../../store/search'
import { TApiVersion, VERSIONS } from "../../index"
import { menuDataType } from "./menuData"
import * as platformMenuData from "../PlatformMainPage/menuData"
import * as sellerCenterMenuData from "../SellerCenterMainPage/menuData"
import * as scCDPMenuData from "../SE_CDP_MainPage/menuData"
import * as scMAMenuData from "../SE_MA_MainPage/menuData"
import * as scIntegrationMenuData from "../SE_Integration_MainPage/menuData"
import * as scCEMenuData from "../SE_CE_MainPage/menuData"
import * as scSettingMenuData from "../SE_Setting_MainPage/menuData"
import * as scShopMenuData from "../SE_Shop_MainPage/menuData"
import * as faasFulfillmentMenuData from "../SE_FAAS_Fulfillment_MainPage/menuData"
import * as faasLoyaltyMenuData from "../SE_FAAS_Loyalty_MainPage/menuData"
import * as faasContentMenuData from "../SE_FAAS_Content_MainPage/menuData"
import * as faasCdpMenuData from "../SE_FAAS_CDP_MainPage/menuData"
import * as faasMaMenuData from "../SE_FAAS_MA_MainPage/menuData"
import { mainPage } from '../RightElement'
import { data as ProductModuleData, ProductProps } from '../../../Home/components/Product'
import { data as ComerceModuleData } from '../../../Home/components/ComposableCommerce'

export type LeftMenuProps = { width?: string; height?: string }

const LeftMenu = styled.div<LeftMenuProps>`
  width: 284px;
  height: ${(props) => props.height};
  border: 1px solid #e6e6e6;
  align-items: center;
  border-bottom: none;
`
const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`
const Img = styled.img`
  width: 48px;
  height: 48px;
`
const Divider = styled.div`
  width: 1px;
  border-left: 1px solid #e6e6e6;
  height: 40px;
`

const menuType: {
  [key: string]: {
    [key in TApiVersion]?: menuDataType[]
  }
} = {
  PlatformAPIs: {
    v10: platformMenuData.platformMenuDataV10,
    v11: platformMenuData.platformMenuDataV11,
  },
  SellerCenter: {
    v10: sellerCenterMenuData.sellerCenterMenuDataV10,
    v11: sellerCenterMenuData.sellerCenterMenuDataV11,
    v16: sellerCenterMenuData.sellerCenterMenuDataV16,
    v17: sellerCenterMenuData.sellerCenterMenuDataV16,
  },
  doc_CDP: {
    v10: scCDPMenuData.scCDPMenuDataV10,
    v11: scCDPMenuData.scCDPMenuDataV11,
  },
  doc_MA: {
    v10: scMAMenuData.scMAMenuDataV10,
    v11: scMAMenuData.scMAMenuDataV11,
  },
  doc_Integration: {
    v10: scIntegrationMenuData.scIntegrationMenuDataV10,
    v17: scIntegrationMenuData.scIntegrationMenuDataV17,
  },
  doc_CE: {
    v10: scCEMenuData.scCEMenuDataV10,
    v11: scCEMenuData.scCEMenuDataV11,
    v16: scCEMenuData.scCEMenuDataV16,
    v17: scCEMenuData.scCEMenuDataV17,
  },
  doc_Setting: {
    v10: scSettingMenuData.scSettingMenuDataV10,
    v11: scSettingMenuData.scSettingMenuDataV11,
    v17: scSettingMenuData.scSettingMenuDataV17,
  },
  doc_Shop: {
    v10: scShopMenuData.scShopMenuDataV10,
    v11: scShopMenuData.scShopMenuDataV11,
  },
  doc_Fulfillment: {
    v10: faasFulfillmentMenuData.faasFulfillmentMenuDataV10,
    v11: faasFulfillmentMenuData.faasFulfillmentMenuDataV11,
    v12: faasFulfillmentMenuData.faasFulfillmentMenuDataV12,
    v13: faasFulfillmentMenuData.faasFulfillmentMenuDataV13,
  },
  doc_Fass_Loyalty: {
    v10: faasLoyaltyMenuData.faasLoyaltyMenuDataV10,
    v11: faasLoyaltyMenuData.faasLoyaltyMenuDataV11,
    v12: faasLoyaltyMenuData.faasLoyaltyMenuDataV12,
    v13: faasLoyaltyMenuData.faasLoyaltyMenuDataV13,
  },
  doc_Fass_Content: {
    v10: faasContentMenuData.faasContentMenuDataV10,
    v11: faasContentMenuData.faasContentMenuDataV11,
    v12: faasContentMenuData.faasContentMenuDataV12,
    v13: faasContentMenuData.faasContentMenuDataV13,
  },
  doc_Fass_CDP: {
    v10: faasCdpMenuData.faasCdpMenuDataV10,
    v11: faasCdpMenuData.faasCdpMenuDataV11,
    v12: faasCdpMenuData.faasCdpMenuDataV12,
    v13: faasCdpMenuData.faasCdpMenuDataV13,
  },
  doc_Fass_MA: {
    v10: faasMaMenuData.faasMaMenuDataV10,
    v11: faasMaMenuData.faasMaMenuDataV11,
    v12: faasMaMenuData.faasMaMenuDataV12,
    v13: faasMaMenuData.faasMaMenuDataV13,
  },
}

export const LeftElement = () => {
  const navigate = useNavigate()
  const [info, setInfo] = useAtom(HttpApiCatalog)
  const { id, version } = useParams<{ id: keyof typeof mainPage, version: TApiVersion }>()

  // const { selected, setSelected } = useSelections(menuType[id!].selecttions)
  const [selected, setSelected] = useState<string>('')

  const ModuleData = ProductModuleData.concat(ComerceModuleData)
  let currenMenuType = menuType[id!][version!]
  if(!currenMenuType){
      let latestVersion = Number((version||'v17').replace('v',''))
      while (!currenMenuType) {
          if(latestVersion==10){
              //@ts-ignore
              currenMenuType =  menuType[id!]?.['v10']// init version
              return
          }
          latestVersion--;
          //@ts-ignore
          currenMenuType =  menuType[id!]?.[`v${latestVersion}`]
        }
  }
  return (
    <div className="flex flex-col w-am290 h-full ">
      <div className="flex flex-row items-center justify-around">
        <div>
          <div
            className="h-am70 w-am273 flex items-center pl-am24 cursor-pointer"
            onClick={() => {
              navigate('/')
              setInfo({ ...info, name: 'default' })
            }}
          >
            {/* <Img src={LOGO_black} /> */}
            {ModuleData.find((t: ProductProps) => t.key === id)?.img() ?? <Img src={LOGO_black} />}
            <span className="ml-1 text-lg">{ModuleData.find((t: ProductProps) => t.key === id)?.title ?? 'Sales Experience'}</span>
          </div>
          <div className="flex items-center justify-end pl-am24">
            <span>version:</span>
            <Select
              value={version}
              bordered={false}
              onChange={(val) => navigate(`/${val}/detail/${id}`)}
              options={VERSIONS}
            />
          </div>
        </div>
        <Divider />
      </div>

      {/* <LeftMenu
        height="70px"
        className="flex pl-am24 cursor-pointer"
        onClick={() => {
          setInfo({ ...info, name: 'default' })
          setSelected([])
        }}
      >
        <Icon src={icon} />
        <div className="text-am_333333 text-am22">{ModuleData.find((t: ProductProps) => t.key === id)?.title ?? 'Sales Experience'} </div>
      </LeftMenu> */}
      <div className="flex-1  overflow-auto">
        {/* <LeftMenu height="70px" className="flex items-center pl-am24">
          <span className="underline text-am_568300 text-am16">Release notes</span>
          <img src={notes} alt="" className="w-am12 h-am14 ml-am5 mt-am5" /> 
        </LeftMenu> */}
        <LeftMenu>
          {(currenMenuType ?? []).map((item) => (
            <div key={item.title} className="text-left border-b border-gray-500 mt-am20">
              <p className=" pl-am24 text-am16 text-am_333333 font-bold">{item.title}</p>
              {item.list.map((child) => (
                <p
                  onClick={() => {
                    setInfo({ name: child })
                    setSelected(child)
                  }}
                  className={`pl-am20 items-center text-am_666666 border-l-4 border-white text-am14 leading-am26 cursor-pointer hover:text-green flex flex-row ${
                    selected === child && 'text-green border-l-4 border-green'
                  }`}
                  key={child}
                >
                  {child}
                </p>
              ))}
            </div>
          ))}
        </LeftMenu>
      </div>
    </div>
  )
}
