import { menuDataType } from "../LeftElement/menuData"

// v10
export const scMAMenuDataV10: menuDataType[] = [
  {
      title: 'OverView',
      list: ['Overview'],
  },
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
  {
      title: 'Campaign Plan',
      list: ['Campaign Plan'],
  },
  {
      title: 'Smart Operation',
      list: ['Process Canvas','Quick Task'],
  },
  {
      title: 'Templates',
      list: ['Email Template', 'SMS Template'],
  },
  {
      title: 'Message Center',
      list: ['Message List'],
  }
]

// v11
export const scMAMenuDataV11: menuDataType[] = [
  {
      title: 'OverView',
      list: ['Overview'],
  },
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
  {
      title: 'Campaign Plan',
      list: ['Campaign Plan'],
  },
  {
      title: 'Smart Operation',
      list: ['Process Canvas','Quick Task'],
  },
  {
      title: 'Templates',
      list: ['Email Template', 'SMS Template'],
  },
  {
      title: 'Message Center',
      list: ['Message List'],
  }
]
