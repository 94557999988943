import styled from 'styled-components'
import MyTitle from '../MyTitle'

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px 4px 0px;
  /* margin-right: 7%; */
  width: 1200px;
  margin: 0 auto;
`
const List = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px dashed #e6e6e6;
  /* line-height: 56px; */
  padding: 10px;
  font-size: 16px;
`
const data = [
  {
    date: '18 April 2023',
    type: 'HTTP API',
    text: 'Release a new V14 version API and launch the Fulfillment Experience module API.',
  },
  {
    date: '7 April 2023',
    type: 'DTC Experience',
    text: 'The V11 version of the DTC Experience product has been released, a new upgrade has been made to the platform',
  },
  {
    date: '27 February 2023',
    type: 'Sales Experience',
    text: 'The V10 version of the Sales Experience product has been released, bringing a new use experience',
  },
  {
    date: '15 May 2022',
    type: 'DTC Platform',
    text: 'The platform has undergone a new architecture upgrade and provided a new graphql API',
  },
  {
    date: '15 March 2022',
    type: 'Global Setting',
    text: 'Unified platform users, roles, permissions, and application management configurations have been released, and single sign-on and other functions have been launched',
  },
  {
    date: '1 August 2021',
    type: 'DTC Platform',
    text: 'The DTC platform publishes content services, which can help enterprises quickly build content services',
  },
    {
    date: '18 April 2021',
    type: 'Fulfillment Experience',
    text: 'Launch a new functional module Fulfillment Experience to achieve unified order management and subsequent fulfillment processes, and achieve full order lifecycle management',
  },
    {
    date: '27 February 2020',
    type: 'HTTP API',
    text: 'The platform fully released v1 version API and products',
  },
  {
    date: '2 February 2020',
    type: 'DTC Platform',
    text: 'The DTC platform publishes content services, which can help enterprises quickly build content services',
  },
  {
    date: '27 April 2019',
    type: 'DTC Platform',
    text: 'The DTC platform releases the core basic service - Sales Experience, which maintains the basic e-commerce services provided by customers',
  },
]

const ReleaseNotes = () => {
  return (
    <div className=" mt-am56 mb-am112">
      <MyTitle>Release Notes</MyTitle>
      <Wrapper className="mt-am18">
        {data.map((item) => (
          <List key={item.text}>
            <div className=" text-left flex flex-row ml-6 text-am_568300">
              <div className="w-40 text-left">{item.date}</div>
              <div className="w-24 text-left">{item.type}</div>
            </div>
            <div className=" text-left ml-28  leading-7">{item.text}</div>
          </List>
        ))}
      </Wrapper>
    </div>
  )
}

export default ReleaseNotes
