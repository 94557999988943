import { atom } from 'jotai'

export interface User {
  id: string
  type: string
  name: string
  nickname: string
  username: string
  email: string
  isEmailVerified: boolean
  phone: string
  isPhoneVerified: boolean
  status: string
}

let data = localStorage.getItem("rc-userInfo") ? JSON.parse(localStorage.getItem("rc-userInfo") as string) : null

export const userAtom = atom<User | null>(data as null || {
  id: '',
  type: '',
  name: '',
  nickname: '',
  username: '',
  email: '',
  isEmailVerified: true,
  phone: '',
  isPhoneVerified: true,
  status: '',
})