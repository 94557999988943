import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  'Overview': {
    v10: '/mdpreview/SC_MA/DTC Center_CDP_Overview.md',
    v11: '/mdpreview/SC_MA/DTC Center_CDP_Overview.md',
    v12: '/mdpreview/SC_MA/DTC Center_CDP_Overview.md',
    v13: '/v13/SC_MA/DTC Center_CDP_Overview.md',
  },
  'Data Ingestion': {
    v10: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_Data Ingestion.md',
    v11: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_Data Ingestion.md',
    v12: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_Data Ingestion.md',
    v13: '/v13/SC_MA/DTC Center_CDP_Data Management_Data Ingestion.md',
  },
  'ID Mapping': {
    v10: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_ID Mapping.md',
    v11: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_ID Mapping.md',
    v12: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_ID Mapping.md',
    v13: '/v13/SC_MA/DTC Center_CDP_Data Management_ID Mapping.md',
  },
  'Meta Data': {
    v10: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_Meta Data.md',
    v11: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_Meta Data.md',
    v12: '/mdpreview/SC_MA/DTC Center_CDP_Data Management_Meta Data.md',
    v13: '/v13/SC_MA/DTC Center_CDP_Data Management_Meta Data.md',
  },
  'Consumer Insights': {
    v10: '/mdpreview/SC_MA/DTC Center_CDP_Insights_Consumer Insights.md',
    v11: '/mdpreview/SC_MA/DTC Center_CDP_Insights_Consumer Insights.md',
    v12: '/mdpreview/SC_MA/DTC Center_CDP_Insights_Consumer Insights.md',
    v13: '/v13/SC_MA/DTC Center_CDP_Insights_Consumer Insights.md',
  },

  'Quick Start': {
    v10: '/mdpreview/SC_MA/CDP1 Quick Start.md',
    v11: '/mdpreview/SC_MA/CDP1 Quick Start.md',
    v12: '/mdpreview/SC_MA/CDP1 Quick Start.md',
    v13: '/v13/SC_MA/CDP1 Quick Start.md',
  },
  'Tags List': {
    v10: '/mdpreview/SC_MA/CDP2 Tags List.md',
    v11: '/mdpreview/SC_MA/CDP2 Tags List.md',
    v12: '/mdpreview/SC_MA/CDP2 Tags List.md',
    v13: '/v13/SC_MA/CDP2 Tags List.md',
  },
  'Add New Tags': {
    v10: '/mdpreview/SC_MA/CDP3 Add New Tags.md',
    v11: '/mdpreview/SC_MA/CDP3 Add New Tags.md',
    v12: '/mdpreview/SC_MA/CDP3 Add New Tags.md',
    v13: '/v13/SC_MA/CDP3 Add New Tags.md',
  },
  'Segments List': {
    v10: '/mdpreview/SC_MA/CDP4 Segments List.md',
    v11: '/mdpreview/SC_MA/CDP4 Segments List.md',
    v12: '/mdpreview/SC_MA/CDP4 Segments List.md',
    v13: '/v13/SC_MA/CDP4 Segments List.md',
  },
  'Add New Segments': {
    v10: '/mdpreview/SC_MA/CDP5 Add New Segments.md',
    v11: '/mdpreview/SC_MA/CDP5 Add New Segments.md',
    v12: '/mdpreview/SC_MA/CDP5 Add New Segments.md',
    v13: '/v13/SC_MA/CDP5 Add New Segments.md',
  },
})

export type CDP_TYPE_KEY = keyof typeof apiPath

export default apiPath