import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
    v12: '',
    v13: '',
  },
  'Dashboard': {
    v10: '/mdpreview/SC_SC/Dashboard.md',
    v11: '/mdpreview/SC_SC/Dashboard.md',
    v12: '/mdpreview/SC_SC/Dashboard.md',
    v13: '/mdpreview/SC_SC/Dashboard.md',
  },
  
  'Product List': {
    v10: '/mdpreview/SC_SC/SE1 Product List.md',
    v11: '/mdpreview/SC_SC/SE1 Product List.md',
    v12: '/mdpreview/SC_SC/SE1 Product List.md',
    v13: '/mdpreview/SC_SC/SE1 Product List.md',
    v16: '/v16/SC_SC/SE1 Product List.md',
    v17: '/v16/SC_SC/SE1 Product List.md',
  },
  'Add Products': {
    v10: '/mdpreview/SC_SC/SE2 Add New Products.md',
    v11: '/mdpreview/SC_SC/SE2 Add New Products.md',
    v12: '/mdpreview/SC_SC/SE2 Add New Products.md',
    v13: '/mdpreview/SC_SC/SE2 Add New Products.md',
  },
  'Search': {
    v10: '/mdpreview/SC_SC/SE3 Search.md',
    v11: '/mdpreview/SC_SC/SE3 Search.md',
    v12: '/mdpreview/SC_SC/SE3 Search.md',
    v13: '/mdpreview/SC_SC/SE3 Search.md',
  },
  'Description': {
    v10: '/mdpreview/SC_SC/SE4 Description.md',
    v11: '/mdpreview/SC_SC/SE4 Description.md',
    v12: '/mdpreview/SC_SC/SE4 Description.md',
    v13: '/mdpreview/SC_SC/SE4 Description.md',
  },
  'Attributes': {
    v10: '/mdpreview/SC_SC/SE5 Attributes.md',
    v11: '/mdpreview/SC_SC/SE5 Attributes.md',
    v12: '/mdpreview/SC_SC/SE5 Attributes.md',
    v13: '/mdpreview/SC_SC/SE5 Attributes.md',
  },
  'Filter&Sort': {
    v10: '/mdpreview/SC_SC/SE6 Filter & Sort.md',
    v11: '/mdpreview/SC_SC/SE6 Filter & Sort.md',
    v12: '/mdpreview/SC_SC/SE6 Filter & Sort.md',
    v13: '/mdpreview/SC_SC/SE6 Filter & Sort.md',
  },

  'Shipment&Order': {
    v10: '/mdpreview/SC_SC/Shipment&Order.md',
    v11: '/mdpreview/SC_SC/Shipment&Order.md',
  },
  'Order Detail': {
    v10: '/mdpreview/SC_SC/OrderDetail.md',
    v11: '/mdpreview/SC_SC/OrderDetail.md',
  },

  'Order Setting': {
    v10: '/mdpreview/SC_SC/OrderSetting.md',
    v11: '/mdpreview/SC_SC/OrderSetting.md',
    v13: '/mdpreview/SC_SC/OrderSetting.md',
    v16: '/v16/SC_SC/OrderSetting.md',
    v17: '/v17/SC_SC/OrderSetting.md',
  },

  'Subscription List': {
    v10: '/mdpreview/SC_SC/SuscriptionList.md',
    v11: '/mdpreview/SC_SC/SuscriptionList.md',
    v16: '/v16/SC_SC/SuscriptionList.md',
    v17: '/v16/SC_SC/SuscriptionList.md',
  },
  'Subscription Detail': {
    v10: '/mdpreview/SC_SC/SubscriptionDetail.md',
    v11: '/mdpreview/SC_SC/SubscriptionDetail.md',
    v16: '/v16/SC_SC/SubscriptionDetail.md',
    v17: '/v16/SC_SC/SubscriptionDetail.md',
  },
  'Subscription Setting': {
    v10: '/mdpreview/SC_SC/SubscriptionSetting.md',
    v11: '/mdpreview/SC_SC/SubscriptionSetting.md',
    v16: '/v16/SC_SC/SubscriptionSetting.md',
    v17: '/v16/SC_SC/SubscriptionSetting.md',
  },

  'Consumer List': {
    v10: '/mdpreview/SC_SC/SE7 Consumer List.md',
    v11: '/mdpreview/SC_SC/SE7 Consumer List.md',
  },
  'Tagging Setting': {
    v10: '/mdpreview/SC_SC/TaggingSetting.md',
    v11: '/mdpreview/SC_SC/TaggingSetting.md',
  },
  'Loyalty Programs': {
    v10: '/mdpreview/SC_SC/SE8 Loyalty Program.md',
    v11: '/mdpreview/SC_SC/SE8 Loyalty Program.md',
  },
  'Points': {
    v10: '/mdpreview/SC_SC/SE9 Points.md',
    v11: '/mdpreview/SC_SC/SE9 Points.md',
  },
  'Tier Management': {
    v10: '/mdpreview/SC_SC/SE10 Tier Management.md',
    v11: '/mdpreview/SC_SC/SE10 Tier Management.md',
  },
  'Referrals': {
    v10: '/mdpreview/SC_SC/SE11 Referrals.md',
    v11: '/mdpreview/SC_SC/SE11 Referrals.md',
  },
  'Reward History': {
    v10: '/mdpreview/SC_SC/SE12 Reward History.md',
    v11: '/mdpreview/SC_SC/SE12 Reward History.md',
  },
  'Reward Settings': {
    v10: '/mdpreview/SC_SC/SE13 Reward Setting.md',
    v11: '/mdpreview/SC_SC/SE13 Reward Setting.md',
  },


  'Voucher list': {
    v10: '/mdpreview/SC_SC/VoucherList.md',
    v11: '/mdpreview/SC_SC/VoucherList.md',
  },
  'Add a voucher': {
    v10: '/mdpreview/SC_SC/add_Vouchers.md',
    v11: '/mdpreview/SC_SC/add_Vouchers.md',
  },
  'Live streaming list': {
    v10: '/mdpreview/SC_SC/LiveStreamingList.md',
    v11: '/mdpreview/SC_SC/LiveStreamingList.md',
  },
  'Intelligent Recommendation': {
    v10: '/mdpreview/SC_SC/Intelligent_recommendation.md',
    v11: '/mdpreview/SC_SC/Intelligent_recommendation.md',
  },

  'Payment Setting': {
    v10: '/mdpreview/SC_SC/Finance/Payment Setting.md',
    v11: '/mdpreview/SC_SC/Finance/Payment Setting.md',
  },

  'Account Management': {
    v10: '/mdpreview/SC_SC/channel/Account_management.md',
    v11: '/mdpreview/SC_SC/channel/Account_management.md',
  },
  'Fans Management': {
    v10: '/mdpreview/SC_SC/channel/Fans.md',
    v11: '/mdpreview/SC_SC/channel/Fans.md',
  },
  'Automatic Replies': {
    v10: '/mdpreview/SC_SC/channel/Automatic.md',
    v11: '/mdpreview/SC_SC/channel/Automatic.md',
  },
  'Reply Content': {
    v10: '/mdpreview/SC_SC/channel/Reply.md',
    v11: '/mdpreview/SC_SC/channel/Reply.md',
  },
  'Assets Management': {
    v10: '/mdpreview/SC_SC/channel/Assets.md',
    v11: '/mdpreview/SC_SC/channel/Assets.md',
  },
  'Menu Management': {
    v10: '/mdpreview/SC_SC/channel/Menu.md',
    v11: '/mdpreview/SC_SC/channel/Menu.md',
  },
  'QR Code Management': {
    v10: '/mdpreview/SC_SC/channel/QR_Code.md',
    v11: '/mdpreview/SC_SC/channel/QR_Code.md',
  },
  'Template Message': {
    v10: '/mdpreview/SC_SC/channel/Template.md',
    v11: '/mdpreview/SC_SC/channel/Template.md',
  },
  'Mini Program QR Code': {
    v10: '/mdpreview/SC_SC/channel/MP_Code.md',
    v11: '/mdpreview/SC_SC/channel/MP_Code.md',
  },
  'Mini Program Banner': {
    v10: '/mdpreview/SC_SC/channel/MP_Banner.md',
    v11: '/mdpreview/SC_SC/channel/MP_Banner.md',
  },

  'Mini Program Message Template Management': {
    v10: '/mdpreview/SC_SC/channel/MP_Message_Template_Management.md',
    v11: '/mdpreview/SC_SC/channel/MP_Message_Template_Management.md',
    v16: '/v16/SC_SC/channel/MP_Message_Template_Management.md',
  },

  'Points Of Sales': {
    v10: '/mdpreview/SC_SC/channel/POS.md',
    v11: '/mdpreview/SC_SC/channel/POS.md',
  },
  'Channel Options': {
    v10: '/mdpreview/SC_SC/channel/Channel Options.md',
    v11: '/mdpreview/SC_SC/channel/Channel Options.md',
  },


  'Partner List': {
    v10: '/mdpreview/SC_SC/partner/Partner List.md',
    v11: '/mdpreview/SC_SC/partner/Partner List.md',
  },
  'Partner Type': {
    v10: '/mdpreview/SC_SC/partner/Partner Type.md',
    v11: '/mdpreview/SC_SC/partner/Partner Type.md',
  },
})

export type SC_TYPE_KEY = keyof typeof apiPath

export default apiPath