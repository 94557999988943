import {defineApiPath} from "../RightElement/apiPath"

const apiPath = defineApiPath({
    default: {
        v10: '',
        v11: '',
    },
    // MA
    'Market Calender': {
        v10: '/mdpreview/Composable_MA/Market_Calendar.md',
        v11: '/mdpreview/Composable_MA/Market_Calendar.md',
        v12: '/mdpreview/Composable_MA/Market_Calendar.md',
        v13: '/v13/Composable_MA/Market_Calendar.md',
    },
    'Process': {
        v10: '/mdpreview/Composable_MA/Process.md',
        v11: '/mdpreview/Composable_MA/Process.md',
        v12: '/mdpreview/Composable_MA/Process.md',
        v13: '/v13/Composable_MA/Process.md',
    },
    'Template': {
        v10: '/mdpreview/Composable_MA/Template.md',
        v11: '/mdpreview/Composable_MA/Template.md',
        v12: '/mdpreview/Composable_MA/Template.md',
        v13: '/v13/Composable_MA/Template.md',
    },
})

export type FAAS_MA_TYPE_KEY = keyof typeof apiPath

export default apiPath