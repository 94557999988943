import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  'Setting Quick Start': {
    v10: '/mdpreview/SC_SETTING/DTC Center_Global Setting_Quick Start.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_Global Setting_Quick Start.md',
  },
  'Basic Information': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Basic Information.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Basic Information.md',
  },
  'Currency': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Currency.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Currency.md',
    v16: '/v16/SC_SETTING/DTC Center_SETTING_Currency.md',
    v17: '/v16/SC_SETTING/DTC Center_SETTING_Currency.md',
  },
  'Measurement Family': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Measurement Family.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Measurement Family.md',
  },
  'Measurement Unit': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Measurement Unit.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Measurement Unit.md',
  },
  'Region Language': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Region Language.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Region Language.md',
    v16: '/v16/SC_SETTING/DTC Center_SETTING_Region Language.md',
    v17: '/v16/SC_SETTING/DTC Center_SETTING_Region Language.md',
  },
  'Shipping Methods': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Shipping Methods.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Shipping Methods.md',
  },
  'Standards and format': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Standards and format.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Standards and format.md',
  },
  'Store Details': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Store Details.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Store Details.md',
  },
  'Tax Setting': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Tax Setting.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Tax Setting.md',
  },
  'Transition Methods': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Transition Methods.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Transition Methods.md',
  },
  'Translation': {
    v10: '/mdpreview/SC_SETTING/DTC Center_SETTING_Translation.md',
    v11: '/mdpreview/SC_SETTING/DTC Center_SETTING_Translation.md',
    v17: '/v17/SC_SETTING/DTC Center_SETTING_Translation.md',
  },
})

export type SE_TYPE_KEY = keyof typeof apiPath

export default apiPath