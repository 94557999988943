import { HttpApiCatalogType } from '../RightElement';
import { SC_TYPE_KEY } from './apiPath';

type TScListItemData = {
  label: SC_TYPE_KEY;
  desc: string;
}

type TScListData = {
  title: string;
  list: TScListItemData[];
}

export const sellerCenterData: TScListData[] = [
  {
    title: 'Dashboard',
    list: [
      {
        label: 'Dashboard',
        desc: 'Displays important Seller Center features and shortcuts.',
      },
    ],
  },
  {
    title: 'Shipment & Order',
    list: [
      {
        label: 'Shipment&Order',
        desc: 'Using the Shipment & Order, you can quickly search for the order in the Order list and access the order details page to management the order. ',
      },
      {
        label: 'Order Detail',
        desc: 'Display order details to manage orders.',
      },
      {
        label: 'Order Setting',
        desc: 'Order Setting',
      },
    ],
  },
  {
    title: 'Subscription',
    list: [
      {
        label: 'Subscription List',
        desc: 'Using the subscription list, you can quickly search for the subscription in the subscription list and access the subscription details page to management the subscription.',
      },
      {
        label: 'Subscription Detail',
        desc: 'Display subscription details to manage subscriptions.  ',
      },
      {
        label: 'Subscription Setting',
        desc: 'Subscription Setting',
      },
    ],
  },
  {
    title: 'Product',
    list: [
      {
        label: 'Product List',
        desc: 'The Product list helps you manage and search for Products in your Project.',
      },
      {
        label: 'Add Products',
        desc: 'How to add Regular or Bundle Product.',
      },
      {
        label: 'Search',
        desc: 'How to add Regular or Bundle Product.',
      },
      {
        label: 'Description',
        desc: 'How to add Regular or Bundle Product.',
      },
      {
        label: 'Attributes',
        desc: 'How to add Regular or Bundle Product.',
      },
      {
        label: 'Filter&Sort',
        desc: 'How to add Regular or Bundle Product.',
      },
    ],
  },
  {
    title: 'Consumer',
    list: [
      {
        label: 'Consumer List',
        desc: 'Using the Consumer List, you can quickly search for the consumer in the consumer list and access the consumer details page to view.',
      },
      {
        label: 'Tagging Setting',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
      {
        label: 'Loyalty Programs',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
      {
        label: 'Points',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
      {
        label: 'Tier Management',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
      {
        label: 'Referrals',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
      {
        label: 'Reward History',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
      {
        label: 'Reward Settings',
        desc: 'Displays the list of tags configured in the Seller Center, with access to tag details and the ability to manage all consumers within the tag. ',
      },
    ],
  },
  {
    title: 'Marketing Center',
    list: [
      {
        label: 'Voucher list',
        desc: 'The Voucher list helps you manage and search for Vouchers.',
      },
      {
        label: 'Add a voucher',
        desc: 'How Vouchers work and how to create them in the Seller Center. ',
      },
      {
        label: 'Live streaming list',
        desc: ' The Live Streaming list helps you view and synchronize for Live Streaming.',
      },
    ],
  },
  {
    title: 'Finance',
    list: [
      {
        label: 'Payment Setting',
        desc: 'The Voucher list helps you manage and search for Vouchers.',
      },
    ],
  },
  {
    title: 'Sales Channel',
    list: [
      {
        label: 'Points Of Sales',
        desc: 'Points Of Sales.',
      },
      {
        label: 'Channel Options',
        desc: 'Channel Options.',
      },
      {
        label: 'Account Management',
        desc: 'The Account Management helps you manage Account list and how to create an account in the Seller Center.',
      },
      {
        label: 'Fans Management',
        desc: 'The fans management helps you view and synchronize for Wechat fans.',
      },
      {
        label: 'Automatic Replies',
        desc: 'The Automatic Replies helps you manage Automatic Replies list and how to create an Automatic Reply in the Seller Center.',
      },
      {
        label: 'Reply Content',
        desc: 'The Reply Content helps you manage Reply Content list and how to create a Reply Content in the Seller Center.',
      },
      {
        label: 'Assets Management',
        desc: 'The Assets Management helps you manage assets list and how to upload an asset in the Seller Center.',
      },
      {
        label: 'Menu Management',
        desc: 'The Menu Management helps you manage menu list and how to create a menu in the Seller Center.',
      },
      {
        label: 'QR Code Management',
        desc: 'The QR Code Management helps you manage QR Code list and how to create a QR Code in the Seller Center.',
      },
      {
        label: 'Template Message',
        desc: 'The Template Message helps you manage Template Message list and how to create a Template Message in the Seller Center.',
      },
      {
        label: 'Mini Program QR Code',
        desc: 'The Mini Program QR Code helps you manage Mini Program QR Code list and how to create a Mini Program QR Code in the Seller Center.',
      },
      {
        label: 'Mini Program Banner',
        desc: 'The Mini Program Banner helps you manage Mini Program Banner list and how to create a Mini Program Banner in the Seller Center.',
      },
    ],
  },
  {
    title: 'Partner',
    list: [
      {
        label: 'Partner List',
        desc: 'The Voucher list helps you manage and search for Vouchers.',
      },
      {
        label: 'Partner Type',
        desc: 'How Vouchers work and how to create them in the Seller Center. ',
      },
    ],
  },
]
