import { menuDataType } from "../LeftElement/menuData"

// v10
export const platformMenuDataV10: menuDataType[] = [
  {
      title: 'General Topics',
      list: ['GraphQL API', 'API Contract', 'Common Types', 'Errors'],
  },
  {
      title: 'Product',
      list: ['Product Category', 'Shop Category', 'Product', 'Product Search', 'Product ribbon', 'Product filter', 'Product sortBy', 'Product description', 'Inventory', 'Uom'],
  },
  {
      title: 'Carts, Orders & Shipping Information',
      list: ['Carts', 'Checkout', 'Pay', 'My Order', 'Shipping Information', 'ShippingMethod', 'Tax'],
  },
  {
      title: 'Ma',
      list: ['Market Calendar'],
  },
  {
      title: 'Marketing',
      list: ['Marketing'],
  },
  {
      title: 'MessageCenter',
      list: ['Message Record'],
  },
  {
      title: 'Consumer',
      list: ['Consumer', 'Consumer Pet', 'Consumer Tag', 'Consumer Address',
          'Consumer Points', 'Consumer Reward History', 'Point Earn Action', 'Point Redeem Action', 'Point Expire Setting',
          'Loyalty Program', 'Loyalty Program Tier', 'Referral Reward Rel', 'Referral Setting'],
  },
  {
      title: 'Subscription',
      list: ['Subscription', 'Recommend'],
  },
  {
      title: 'Organization',
      list: ['User', 'Change password', 'Organization'],
  },
  {
      title: 'Store',
      list: ['Store'],
  },
  {
      title: 'Consumer Data Platform',
      list: ['Tag', 'Segment', 'Consumer Insight'],
  },
  {
      title: 'Workflow',
      list: ['Activity Log'],
  }
];

// v11
export const platformMenuDataV11: menuDataType[] = [
  {
      title: 'General Topics',
      list: ['GraphQL API', 'API Contract', 'Common Types', 'Errors'],
  },
  {
      title: 'Product',
      list: ['Product Category', 'Shop Category', 'Product', 'Product Search', 'Product ribbon', 'Product filter', 'Product sortBy', 'Product description', 'Inventory', 'Uom'],
  },
  {
      title: 'Carts, Orders & Shipping Information',
      list: ['Carts', 'Checkout', 'Pay', 'My Order', 'Shipping Information', 'ShippingMethod', 'Tax'],
  },
  {
      title: 'Ma',
      list: ['Market Calendar'],
  },
  {
      title: 'Marketing',
      list: ['Marketing'],
  },
  {
      title: 'MessageCenter',
      list: ['Message Record'],
  },
  {
      title: 'Consumer',
      list: ['Consumer', 'Consumer Pet', 'Consumer Tag', 'Consumer Address',
          'Consumer Points', 'Consumer Reward History', 'Point Earn Action', 'Point Redeem Action', 'Point Expire Setting',
          'Loyalty Program', 'Loyalty Program Tier', 'Referral Reward Rel', 'Referral Setting'],
  },
  {
      title: 'Subscription',
      list: ['Subscription', 'Recommend'],
  },
  {
      title: 'Organization',
      list: ['User', 'Change password', 'Organization'],
  },
  {
      title: 'Store',
      list: ['Store'],
  },
  {
      title: 'Consumer Data Platform',
      list: ['Tag', 'Segment', 'Consumer Insight'],
  },
  {
      title: 'Workflow',
      list: ['Activity Log'],
  }
];
