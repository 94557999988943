import { defineApiPath } from "../RightElement/apiPath"

const apiPath = defineApiPath({
  default: {
    v10: '',
    v11: '',
  },
  'Overview': {
    v10: '/mdpreview/SC_MA/DTC Center_MA_Overview.md',
    v11: '/mdpreview/SC_MA/DTC Center_MA_Overview.md',
    v12: '/mdpreview/SC_MA/DTC Center_MA_Overview.md',
    v13: '/v13/SC_MA/DTC Center_MA_Overview.md',
  },
  'Quick Start': {
    v10: '/mdpreview/SC_MA/DTC Center_MA_Quick Start.md',
    v11: '/mdpreview/SC_MA/DTC Center_MA_Quick Start.md',
    v12: '/mdpreview/SC_MA/DTC Center_MA_Quick Start.md',
    v13: '/v13/SC_MA/DTC Center_MA_Quick Start.md',
  },

  'Campaign Plan': {
    v10: '/mdpreview/SC_MA/DTC Center_MA_Campaign Plan.md',
    v11: '/mdpreview/SC_MA/DTC Center_MA_Campaign Plan.md',
    v12: '/mdpreview/SC_MA/DTC Center_MA_Campaign Plan.md',
    v13: '/v13/SC_MA/DTC Center_MA_Campaign Plan.md',
  },
  'Process Canvas': {
    v10: '/mdpreview/SC_MA/DTC Center_MA_Smart Operation_Process Canvas.md',
    v11: '/mdpreview/SC_MA/DTC Center_MA_Smart Operation_Process Canvas.md',
    v12: '/mdpreview/SC_MA/DTC Center_MA_Smart Operation_Process Canvas.md',
    v13: '/v13/SC_MA/DTC Center_MA_Smart Operation_Process Canvas.md',
    v16: '/v16/SC_MA/DTC Center_MA_Smart Operation_Process Canvas.md',
    v17: '/v16/SC_MA/DTC Center_MA_Smart Operation_Process Canvas.md',
  },

  'Quick Task': {
    v10: '/mdpreview/SC_MA/MA1 Quick Task.md',
    v11: '/mdpreview/SC_MA/MA1 Quick Task.md',
    v12: '/mdpreview/SC_MA/MA1 Quick Task.md',
    v13: '/v13/SC_MA/MA1 Quick Task.md',
    v16: '/v16/SC_MA/MA1 Quick Task.md',
    v17: '/v16/SC_MA/MA1 Quick Task.md',
  },
  'Email Template': {
    v10: '/mdpreview/SC_MA/MA2 Email Template.md',
    v11: '/mdpreview/SC_MA/MA2 Email Template.md',
    v12: '/mdpreview/SC_MA/MA2 Email Template.md',
    v13: '/v13/SC_MA/MA2 Email Template.md',
  },
  'SMS Template': {
    v10: '/mdpreview/SC_MA/MA3 SMS Template.md',
    v11: '/mdpreview/SC_MA/MA3 SMS Template.md',
    v12: '/mdpreview/SC_MA/MA3 SMS Template.md',
    v13: '/v13/SC_MA/MA3 SMS Template.md',
  },
  'Message List': {
    v10: '/mdpreview/SC_MA/MA4 Message List.md',
    v11: '/mdpreview/SC_MA/MA4 Message List.md',
    v12: '/mdpreview/SC_MA/MA4 Message List.md',
    v13: '/v13/SC_MA/MA4 Message List.md',
  },
})

export type MA_TYPE_KEY = keyof typeof apiPath

export default apiPath