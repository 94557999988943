import ReactJson from 'react-json-view'

const text = {
  error: {
    root_cause: [
      {
        type: 'parsing_exception',
        reason: 'No text specified for multi_match query',
        line: 12,
        col: 2,
      },
    ],
    type: 'parsing_exception',
    reason: 'No text specified for multi_match query',
    line: 12,
    col: 2,
  },
  status: 400,
}

const title = 'text-am20 font-black pb-am14'
const Subtitle = 'text-am18 font-medium pb-am14'
const content = 'text-am16 pb-am41'
export const TechnicalContent = () => {
  return (
    <div className="pl-am44 flex-1">
      <div className={title}>1. Access</div>
      <div className={Subtitle}>1.1 Authorization</div>
      <div className={content}>
        Approved clients are authorized to access API resource. Register your App or client to access this API. For more
        information click here
      </div>
      <div className={Subtitle}>1.2 API Manager Policies</div>
      <div className={content}>
        This API is enabled with Client ID enforcement policy. To register your client app to access this API, kindly
        refer Client ID enforcement documentation here
      </div>
      <div className={title}>2. Functional Errors</div>
      <div className={content}>
        In case of invalid json request submitted in post body, then a code HTTP 200 is returned, with the following
        error message in the body.
      </div>
      <div className=" bg-gray-500 mb-am30">
        <ReactJson src={text} />
      </div>
      <div className={title}>3. Support</div>
      <div className={content}>
        <p>
          Operations Support: <span className=" text-green">Global AIT Operations</span>
        </p>
        <p>
          Additional Information: <span className=" text-green">Global Mulesoft Architect</span>
        </p>
      </div>
      <div className={title}>4. Change Log</div>
      <div className={content}>Not Applicable</div>
    </div>
  )
}
