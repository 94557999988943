import { Col, Row } from 'antd'
import _ from 'lodash'
import { useState } from 'react'
import { IconFont } from '../../../../components/common/IconFont'
import { SearchInput } from '../../../../components/common/Layout/Header/components/SearchInput'
import Wrapper from '../../../../components/common/Wrapper'
import { ApiTag } from '../../../Search/components/ApiTag'

const data = [
  {
    title: 'Product API',
    desc: 'Query and manage product data, including SPU, SKU, picture, specification, attribute and product classification',
  },
  {
    title: 'Consumer API',
    desc: 'Query and manage consumer data, including basic information, address, pet and registration',
  },
  {
    title: 'Order API',
    desc: 'Query and manage order information, operate order shipment, and configure basic order information',
  },
  {
    title: 'Subscription API',
    desc: 'Query and manage subscription information, and operate subscription update and shipment',
  },
  {
    title: 'Payment API',
    desc: 'Provide payment related configurations and payment process API',
  },
  {
    title: 'Inventory&Price API',
    desc: 'Basic information of the management platform, including users, roles, permissions, operation logs, etc',
  },
  {
    title: 'Marketing&Promotion API',
    desc: 'Manage the marketing activities, coupons, discounts and other information of the platform, and analyze and count the marketing results',
  },
  {
    title: 'Content API',
    desc: 'Content management and content directory management, free to create independent content web pages, including page layout, advertising, navigation, etc',
  },
  {
    title: 'Automation API',
    desc: 'Analyze and summarize platform data, generate statistical reports, and provide data for visualization',
  },
  {
    title: 'Store API',
    desc: 'Import and export of platform data, including product catalog, products, members, orders, subscriptions, etc',
  },
  {
    title: 'Message API',
    desc: '',
  },
  {
    title: 'Finance API',
    desc: '',
  },
  {
    title: 'Auth API',
    desc: '',
  },
]
export const Catalog = () => {
  const [value, setValue] = useState('')

  return (
    <div className="w-am1200 m-auto text-left">
      <div className=" text-am26 font-bold my-am50">API Catalog ({data.length})</div>
      <div>Filter by Keywords</div>
      <SearchInput
        style={{ width: 379, marginTop: 14 }}
        placeholder="input search content"
        value={value}
        onChange={setValue}
      />
      {_.chunk(data, 3).map((item: any, index: number) => (
        <Row key={index} gutter={25}>
          {item.map((child: any, key: number) => (
            <Col span={8} key={key}>
              <Wrapper width="382px" height="240px" className="my-am28">
                <ApiTag desc={child.desc} title={child.title} />
              </Wrapper>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  )
}
