import { useSelections } from 'ahooks'
import { useAtom } from 'jotai'
import { SearchAtom } from '../../../../../store/search'
import { HomeContent } from '../HomeContent'
import { TechnicalContent } from '../TechnicalContent'

const tabItems: Content[] = ['Home', 'TechnicalSection']
const content = {
  Home: <HomeContent />,
  TechnicalSection: <TechnicalContent />,
} as const
export type Content = keyof typeof content
export const DocumentElement = () => {
  const { selected, setSelected } = useSelections(tabItems, ['Home'])
  const [info, setInfo] = useAtom(SearchAtom)
  return (
    <div className="flex flex-row">
      <div>
        {tabItems.map((item, index) => {
          return (
            <div
              className={`border-t border-gray-500 h-am49 flex items-center pl-am_9 cursor-pointer text-am14  w-am273 ${
                selected?.[0] === item ? ' bg-bgGreen text-green' : 'bg-gray-200 '
              } `}
              key={index}
              onClick={() => {
                setSelected([item])
                setInfo({ ...info, documentType: item })
              }}
            >
              {item}
            </div>
          )
        })}
      </div>

      <div>{content[info.documentType]}</div>
    </div>
  )
}
