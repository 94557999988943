import { HttpApiCatalogType } from '../RightElement';
import { CDP_TYPE_KEY } from './apiPath';

type TScListItemData = {
  label: CDP_TYPE_KEY;
  desc: string;
}

type TScListData = {
  title: string;
  list: TScListItemData[];
}

export const cdpData: TScListData[] = [
  {
    title: 'OverView',
    list: [
      {
        label: 'Overview',
        desc: 'Displays important CDP features and shortcuts.',
      },
    ],
  },
  {
    title: 'Quick Start',
    list: [
      {
        label: 'Quick Start',
        desc: 'Displays important CDP features and shortcuts.',
      },
    ],
  },
  {
    title: 'Data Management',
    list: [
      {
        label: 'Meta Data',
        desc: 'Displays important CDP features and shortcuts.',
      },
      {
        label: 'Data Ingestion',
        desc: 'Displays important CDP features and shortcuts.',
      },
      {
        label: 'ID Mapping',
        desc: 'Displays important CDP features and shortcuts.',
      },
    ],
  },
  {
    title: 'Insights',
    list: [
      {
        label: 'Consumer Insights',
        desc: 'Displays important CDP features and shortcuts.',
      },
    ],
  },
  {
    title: 'Tag Management',
    list: [
      {
        label: 'Tags List',
        desc: 'Displays important CDP features and shortcuts.',
      },
      {
        label: 'Add New Tags',
        desc: 'Displays important CDP features and shortcuts.',
      },
    ],
  },
  {
    title: 'Segments Management',
    list: [
      {
        label: 'Segments List',
        desc: 'Displays important CDP features and shortcuts.',
      },
      {
        label: 'Add New Segments',
        desc: 'Displays important CDP features and shortcuts.',
      },
    ],
  },
]
