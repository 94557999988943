import Api_home from '../../../../../assets/image/api_home.jpg'

const title = 'text-am20 font-black pb-am14'
const content = 'text-am16 pb-am41'

export const HomeContent = () => {
  return (
    <div className="pl-am44 flex-1">
      <div className={title}>1. Overview</div>
      <div className={content}>
        This API provides product related interface services. After the API consumer invokes the interface, it performs
        flow limiting authentication through the gateway, and finally requests to reach the API service
      </div>
      <img src={Api_home} alt="" className="w-am750  h-am420 m-auto" />
      <div className={title}>2. Business Benefits</div>
      <div className={content}>
        The existing Royal Canin product catalogs (LPC / LSH in XML) have been replaced by WeShare which greatly
        improves the way of managing and maintaining the data.Secondly the product and breed data are now available in a
        an easily consumable JSON format. Updates that have been published in the catalog are available through the API
        instantly.
      </div>
    </div>
  )
}
