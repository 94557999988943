import { menuDataType } from "../LeftElement/menuData"

// v10
export const scCDPMenuDataV10: menuDataType[] = [
  {
      title: 'OverView',
      list: ['Overview'],
  },
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
   {
      title: 'Data Management',
      list: ['ID Mapping','Data Ingestion','Meta Data'],
  },
  {
      title: 'Insights',
      list: ['Consumer Insights'],
  },
  {
      title: 'Tag Management',
      list: ['Tags List','Add New Tags'],
  },
  {
      title: 'Segments Management',
      list: ['Segments List', 'Add New Segments'],
  }
]

// v11
export const scCDPMenuDataV11: menuDataType[] = [
  {
      title: 'OverView',
      list: ['Overview'],
  },
  {
      title: 'Quick Start',
      list: ['Quick Start'],
  },
   {
      title: 'Data Management',
      list: ['ID Mapping','Data Ingestion','Meta Data'],
  },
  {
      title: 'Insights',
      list: ['Consumer Insights'],
  },
  {
      title: 'Tag Management',
      list: ['Tags List','Add New Tags'],
  },
  {
      title: 'Segments Management',
      list: ['Segments List', 'Add New Segments'],
  }
]
