import { LeftElement } from './components/LeftElement'
import { RightElement } from './components/RightElement'

export type TApiVersion = "v10" | "v11" | "v12" | "v13" | "v14"| "v16" | "v17"| "v18"| "v19" | "v20" ;
export const VERSIONS: { label: string, value: string }[] = [
  {
    label: 'v10',
    value: 'v10',
  },
  {
    label: 'v11',
    value: 'v11',
  },
  {
    label: 'v12',
    value: 'v12',
  },
  {
    label: 'v13',
    value: 'v13',
  },
  {
    label: "v14",
    value: "v14"
  },
  {
    label: "v16",
    value: "v16"
  },
  {
    label: "v17",
    value: "v17"
  },
  {
    label: "v18",
    value: "v18"
  },
  {
    label: "v19",
    value: "v19"
  },
  {
    label: "v20",
    value: "v20"
  }
];

export default function Detail() {
  return (
    <div className="flex flex-row h-full">
      <LeftElement />
      <div className="flex-1 overflow-auto">
        <RightElement />
      </div>
    </div>
  )
}
