import { Button, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
const { Title } = Typography

interface DataType {
  key: string
  name: string
  status: string
  environment: string
  tier: string
}

const data: DataType[] = [
  {
    key: '1',
    name: 'Consumer API',
    status: 'Approved',
    environment: 'DEV',
    tier: '99.99% ',
  },
  {
    key: '2',
    name: 'Product API',
    status: 'Pending',
    environment: 'DEV',
    tier: '99.99%',
  },
  {
    key: '3',
    name: 'Consumer API',
    status: 'Approved',
    environment: 'STG',
    tier: '99.99%',
  },
]

const APIContracts = () => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'API',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Environment / Instance',
      dataIndex: 'environment',
      key: 'environment',
      align: 'center',
      width: '25%',
    },
    {
      title: 'SLA Tier',
      dataIndex: 'tier',
      key: 'tier',
      align: 'center',
      width: '25%',
    },
    {
      title: 'Request Status',
      key: 'Credentials',
      width: '15%',
      render: (_: any, record: any) => (
        <div className="flex items-center">
          <div className="CredentialsIcon" />
          <Button className="text-am_6CA100" type="text">
            {record.status}
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div className="w-am1200 m-auto text-left">
      <Title className="title" style={{ marginBottom: '17px', marginTop: '50px' }} level={4}>
        API Contracts
      </Title>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  )
}

export default APIContracts
