import { AMCarousel } from './components/AMCarousel'
import { AMSearch } from './components/AMSearch'

export const Search = () => {
  return (
    <div>
      <AMSearch />
      <AMCarousel />
    </div>
  )
}
