import { menuDataType } from "../LeftElement/menuData"

// v10
export const scIntegrationMenuDataV10: menuDataType[] = [
  // {
  //     title: 'Integration Portal',
  //     list: ['Integration Portal'],
  // }
]

// v17
export const scIntegrationMenuDataV17: menuDataType[] = [
  {
      title: 'Integration Portal',
      list: ['Integration Portal'],
  }
]
