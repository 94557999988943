import styled from 'styled-components'
// import LOGO_white from '../../../../assets/image/LOGO_white.png'
// import LOGO_black from '../../../../assets/image/LOGO_black.png'
import LOGO_white from '../../../../assets/image/logo-white.svg'
import LOGO_black from '../../../../assets/image/logo-black.png'
import user from '../../../../assets/image/user.png'
import { SearchTab } from './components/SearchTab'
import { useLocation, useNavigate } from 'react-router-dom'
import { HeaderBox } from '../../Layout/Header/components/HeaderBox'
import { useAtom } from 'jotai'
import { SearchAtom } from '../../../../store/search'
import { userAtom } from '@/store/user.store'
import { removeToken } from "@/utils/utils"

const Img = styled.img`
  width: 48px;
  height: 48px;
  line-height: 22px;
`
const Avatar = styled.img`
  background: url(${user}) center no-repeat;
  width: 32px;
  height: 32px;
  background-size: 100% 100%;
  border-radius: 50%;
`
export const SearchHeader = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [info, setInfo] = useAtom(SearchAtom)
  const [userInfo, setUserInfo] = useAtom(userAtom)

  const logout = () => {
    removeToken()
    localStorage.removeItem('rc-userInfo')
    localStorage.removeItem("rc_access_cliant")
    setUserInfo(null)
    navigate('/')
  }

  return (
    <HeaderBox backGroundColor={`${location.pathname !== '/console/search' ? '#ffffff' : '#0b121c'}`}>
      <div className="w-am1200 m-auto h-am70 flex items-center ">
        <Img
          src={location.pathname === '/console/search' ? LOGO_white : LOGO_black}
          className="mr-14 cursor-pointer"
          onClick={() => {
            navigate('/')
            setInfo({ ...info, current: 0 })
          }}
        />
        <div className="flex flex-row w-full items-center justify-between">
          <SearchTab />
          <div className="flex items-center">
            {
              userInfo?.id ? <>
                <div className="rounded-full text-center text-lg text-white leading-[32px]" style={{ background: '#397DFF', width: 32, height: 32, lineHeight: '32px' }}>{userInfo.username.substring(0, 1)}</div>
                <span className={`ml-2 ${location.pathname === '/console/search' ? 'text-white': 'text-am_333333'}`}>{userInfo.username}</span>
                <span className={`ml-2 ${location.pathname === '/console/search' ? 'text-white': 'text-am_333333'}`}>|</span>
                <span className={`ml-2 cursor-pointer ${location.pathname === '/console/search' ? 'text-white': 'text-am_333333'}`} onClick={logout}>Logout</span>
              </> : <>
                <Avatar />
                <a className={`ml-2 ${location.pathname === '/console/search' ? 'text-white': 'text-am_333333'}`} href={`${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${process.env.REACT_APP_CLIENT_ID}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`}>Login</a>
              </>
            }
          </div>
        </div>
      </div>
    </HeaderBox>
  )
}
