import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { PageWrapper } from '../PageWrapper'
import useScrollToTop from '../../../hooks/useScrollToTop'
import { SearchHeader } from './SearchHeader'
import { useAtom } from 'jotai'
import { userAtom } from '@/store/user.store'
import { Footer } from './Footer'

export const ConsoleLayout = () => {
  const location = useLocation()
  const [userInfo] = useAtom(userAtom)

  useScrollToTop('page-content')

  useEffect(() => {
    if (!userInfo?.id) {
      // window.location.href = 'https://api.dev.d2cgo.com/auth/v13/oauth/authorized?clientId=9c1d8cc0-c975-4fb5-991f-a7c652bee891&loginUrl=https://auth.dev.d2cgo.com/login&state=zero'
      window.location.replace(`${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${process.env.REACT_APP_CLIENT_ID}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`)
    }
  }, [userInfo])

  if (!userInfo?.id) {
    return null;
  }

  return (
    <div className="flex flex-col h-full">
      <SearchHeader />
      <PageWrapper id="page-content">
        <Outlet />
        <Footer />
      </PageWrapper>
    </div>
  )
}
