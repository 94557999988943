import styled from 'styled-components'
import { IconFont } from '../../../../components/common/IconFont'
import { MyInput } from '../../../../components/common/Layout/Header/components/SearchInput'
import { CloseOutlined } from '@ant-design/icons'
import { useState } from 'react'

const HomeInput = styled(MyInput)`
  width: 766px;
  height: 50px;
  border: 1px solid #8b8b8b;
  background: #191738;
  margin: 0 auto;
  color: #ffffff;
  border-radius: 4px;
  .ant-input {
    background: #191738;
    color: #ffffff;
  }
`
export const HomeSearchInput = () => {
  const [value, setValue] = useState('')

  return (
    <HomeInput
      placeholder="Input search content"
      suffix={value && <CloseOutlined style={{ color: '#9E9898' }} onClick={() => setValue('')} />}
      prefix={<IconFont type="icon-bianzu" />}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  )
}
