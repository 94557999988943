import styled from 'styled-components'
import search_bg from '../../../../assets/image/search_bg.png'
import { HomeSearchInput } from '../HomeSearchInput'

const Img = styled.div`
  background: url(${search_bg}) center no-repeat;
  height: 311px;
  width: 100%;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AMSearch = () => {
  return (
    <Img>
      <HomeSearchInput />
    </Img>
  )
}
